import React from "react";

import { StyledHeadingSix } from "../../styles/styledTypography";
import { StyledAlertContainer, StyledAlertWrapper } from "./styledAlert";

const AlertMessage = ({
  children,
  message,
  type,
  mb,
  mt,
  width,
  className,
  center,
  border,
  title = null,
}) => {
  return (
    <StyledAlertWrapper
      width={width}
      center={center}
      className={className}
      type={type}
      mt={mt}
      mb={mb}
      border={!border}
    >
      {title && <StyledHeadingSix mb="8">{title}</StyledHeadingSix>}
      <StyledAlertContainer>{message || children}</StyledAlertContainer>
    </StyledAlertWrapper>
  );
};

export default AlertMessage;
