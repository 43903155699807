import { t } from "i18next";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../utils/errorsParser";
import { changeExchangerPairUnitActivity } from "../pairUnit/exchanger/asyncPairUnitSlice";
import {
  changeWithdrawalRequisitionStatus,
  getWithdrawalRequisitions,
} from "./asyncWithdrawalRequisitions";

const getWithdrawalRequisitionsReducer = (builder) => {
  builder.addCase(getWithdrawalRequisitions.fulfilled, (state, action) => {
    state.withdrawalRequisitions = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(getWithdrawalRequisitions.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const changeWithdrawalRequisitionStatusReducer = (builder) => {
  builder.addCase(changeWithdrawalRequisitionStatus.pending, (state) => {
    state.statusUpdateLoading = true;
  });
  builder.addCase(changeWithdrawalRequisitionStatus.fulfilled, (state, action) => {
    const newObjectPairUnit = action.payload.data;
    state.withdrawalRequisitions = state.withdrawalRequisitions.map((item) => {
      if (item.id === newObjectPairUnit.id) {
        return {
          ...item,
          status: newObjectPairUnit.status,
          comment: newObjectPairUnit.comment,
        };
      }

      return item;
    });

    !!action.payload.callback && action.payload.callback();
    closableNotification(t("alerts.statusChanged"), "success");
    state.statusUpdateLoading = false;
  });
  builder.addCase(changeWithdrawalRequisitionStatus.rejected, (state, action) => {
    errorsParser(action.payload);
    state.statusUpdateLoading = false;
  });
};

export { changeWithdrawalRequisitionStatusReducer, getWithdrawalRequisitionsReducer };
