import { createSlice } from "@reduxjs/toolkit";
import { t } from "i18next";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../utils/errorsParser";
import {
  fetchEndpoints,
  fetchEndpointsByUser,
  putEndpointsPermissions,
} from "./asyncEndpointSlice";

const initialState = {
  endpoints: [],
  endpointsByUser: null,
  endpointsRequestData: [],
  error: null,
  loading: true,
  loadingEndpointsByUser: true,
  loadingPutEndpoints: false,
};

const endpointSlice = createSlice({
  name: "endpointSlice",
  initialState,
  reducers: {
    clearStateEndpointsByUser: (state) => {
      state.endpointsByUser = null;
      state.loadingEndpointsByUser = true;
      state.error = null;
    },
    updateEndpointsRequestData: (state, action) => {
      state.endpointsRequestData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEndpoints.fulfilled, (state, action) => {
      state.endpoints = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchEndpointsByUser.pending, (state) => {
      state.loadingEndpointsByUser = true;
    });
    builder.addCase(fetchEndpointsByUser.fulfilled, (state, action) => {
      state.endpointsByUser = action.payload.data;
      state.loadingEndpointsByUser = false;
    });
    builder.addCase(fetchEndpointsByUser.rejected, (state, action) => {
      errorsParser(action.payload);
      state.loadingEndpointsByUser = false;
    });
    builder.addCase(putEndpointsPermissions.pending, (state) => {
      state.loadingPutEndpoints = true;
    });
    builder.addCase(putEndpointsPermissions.fulfilled, (state) => {
      state.errors = null;
      state.loadingPutEndpoints = false;
      closableNotification(t("alerts.permissionsUpdated"), "success");
    });
    builder.addCase(putEndpointsPermissions.rejected, (state, action) => {
      errorsParser(action.payload);
      state.loadingPutEndpoints = false;
    });
  },
});

export const { clearStateEndpointsByUser, updateEndpointsRequestData } = endpointSlice.actions;

export default endpointSlice.reducer;
