import { crmSidebar } from "../../../rbac-consts";
import { typeAccountRoutes } from "../../../utils/consts";
import { permissionUserEndpoints } from "../../../utils/groupEndpoints/exchanger/user";
import { groupPermissionEndpoints } from "../../../utils/groupEndpoints/groupPermissionEndpoints";

const sidebarRoutes = [
  // {
  //   title: "dashboard",
  //   path: `${typeAccountRoutes.CRM}/dashboard`,
  //   icon: "icon-presentation"
  // },
  {
    title: "exchanges",
    path: `${typeAccountRoutes.CRM}/orders`,
    permission: [
      groupPermissionEndpoints.API_EXCHANGER_ORDERS_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGER_ORDERS_GET_ITEM,
    ],
    icon: "icon-transfer-vertical",
  },
  {
    title: "widgets",
    path: `${typeAccountRoutes.CRM}/widget`,
    permission: [groupPermissionEndpoints.API_EXCHANGER_WIDGETS_GET_COLLECTION],
    icon: "icon-calculator",
  },
  {
    title: "referralSystem",
    path: `${typeAccountRoutes.CRM}/referral`,
    permission: [permissionUserEndpoints.GET_EXCHANGER_REFERRAL_TOKEN],
    checkFullAccess: true,
    icon: "icon-link",
  },
  {
    title: "documents",
    path: `${typeAccountRoutes.CRM}/documents`,
    permission: [
      groupPermissionEndpoints.GET_EXCHANGER_USER_VERIFICATIONS,
      groupPermissionEndpoints.POST_EXCHANGER_USER_VERIFICATION,
    ],
    icon: "icon-verification",
  },
  {
    title: "savedRequisites",
    path: `${typeAccountRoutes.CRM}/saved-requisites`,
    permission: [permissionUserEndpoints.GET_EXCHANGER_USER_REQUISITES],
    checkFullAccess: true,
    icon: "icon-id-card",
  },
  {
    title: "exchanger",
    path: `${typeAccountRoutes.CRM}/exchanger`,
    permission: [
      groupPermissionEndpoints.API_EXCHANGERS_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGERS_POST_COLLECTION,
    ],
    icon: "icon-coins-rotate",
  },
  {
    title: "transaction",
    path: `${typeAccountRoutes.CRM}/transactions`,
    permission: [
      groupPermissionEndpoints.CREATE_PAYMENT,
      groupPermissionEndpoints.CREATE_PAYOUT,
      groupPermissionEndpoints.GET_INVOICES_LIST,
      groupPermissionEndpoints.GET_INVOICE_DETAILS,
    ],
    icon: "icon-transfer-vertical",
    perform: crmSidebar.TRANSACTION,
  },
  {
    title: "services",
    path: `${typeAccountRoutes.CRM}/services`,
    permission: [groupPermissionEndpoints.API_SERVICES_GET_COLLECTION],
    icon: "icon-bank",
  },
  {
    title: "paymentSystems",
    path: `${typeAccountRoutes.CRM}/payment-systems`,
    permission: [
      groupPermissionEndpoints.API_PAYMENT_SYSTEMS_GET_COLLECTION,
      groupPermissionEndpoints.API_PAIR_UNITS_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGER_PAIR_UNITS_GET_COLLECTION,
    ],
    icon: "icon-dollar-square",
  },
  {
    title: "rates",
    path: `${typeAccountRoutes.CRM}/rates`,
    permission: [
      groupPermissionEndpoints.API_EXCHANGER_RATES_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGER_RATES_GET_ITEM,
      groupPermissionEndpoints.API_EXCHANGER_RATES_CHANGE_OUR_MARKUP_ITEM,
      groupPermissionEndpoints.GET_EXCHANGER_RATES,
      groupPermissionEndpoints.API_GET_EXCHANGER_RATES_COLLECTION,
    ],
    icon: "icon-line-chart-up",
  },
  {
    title: "pairs",
    path: `${typeAccountRoutes.CRM}/pairs`,
    permission: [groupPermissionEndpoints.API_EXCHANGER_PAIRS_GET_COLLECTION],
    icon: "icon-coins-rotate",
  },
  {
    title: "balances",
    path: `${typeAccountRoutes.CRM}/balances`,
    permission: [
      groupPermissionEndpoints.API_EXCHANGER_BALANCES_GET_COLLECTION,
      groupPermissionEndpoints.API_BALANCES_GET_COLLECTION,
    ],
    icon: "icon-wallet",
  },
  {
    title: "reports",
    path: `${typeAccountRoutes.CRM}/reports`,
    permission: [groupPermissionEndpoints.GET_EXCHANGER_ORDERS_REPORT],
    icon: "icon-reports",
  },
  {
    title: "servers",
    path: `${typeAccountRoutes.CRM}/servers`,
    permission: [groupPermissionEndpoints.API_SERVERS_GET_COLLECTION],
    icon: "icon-folder-cloud",
  },
  {
    title: "exchangerDatabaseConnection",
    path: `${typeAccountRoutes.CRM}/database-exchanger`,
    permission: [groupPermissionEndpoints.API_EXCHANGER_DATABASE_CONNECTIONS_GET_COLLECTION],
    icon: "icon-data",
  },
  {
    title: "users",
    path: `${typeAccountRoutes.CRM}/users`,
    permission: [
      groupPermissionEndpoints.API_USERS_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGER_USERS_GET_COLLECTION,
    ],
    icon: "icon-users-profiles",
  },
  {
    title: "withdrawalRequisitions",
    path: `${typeAccountRoutes.CRM}/withdrawal-requisitions`,
    permission: [groupPermissionEndpoints.API_EXCHANGER_WITHDRAWAL_REQUISITION_GET_COLLECTION],
    icon: "icon-money-change",
    checkFullAccess: true,
  },
  {
    title: "systemCommands",
    path: `${typeAccountRoutes.CRM}/system-commands`,
    permission: [
      groupPermissionEndpoints.FILL_PAIR_UNITS,
      groupPermissionEndpoints.FILL_ENDPOINTS,
      groupPermissionEndpoints.FILL_GROUP_AND_ENDPOINT_PERMISSIONS,
      groupPermissionEndpoints.CHECK_DIFFERENCE_BETWEEN_ENDPOINTS_AND_GROUP_ENDPOINTS,
      groupPermissionEndpoints.UPDATE_DATABASE_SCHEMA,
      groupPermissionEndpoints.CHECK_DIFFERENCE_BETWEEN_ENDPOINTS_AND_GROUP_ENDPOINTS,
      groupPermissionEndpoints.UPDATE_DATABASE_SCHEMA_FOR_ALL_EXCHANGERS,
      groupPermissionEndpoints.FILL_BASE_CURRENCY_RATE_SERVICE,
      groupPermissionEndpoints.FILL_EXCHANGER_RATE,
    ],
    icon: "icon-terminal",
  },
  {
    title: "settings",
    path: `${typeAccountRoutes.CRM}/settings`,
    permission: [
      groupPermissionEndpoints.API_USERS_GET_ITEM,
      groupPermissionEndpoints.API_USERS_CHANGE_PASSWORD,
      groupPermissionEndpoints.PUT_EXCHANGER_USERS_CHANGE_PASSWORD,
    ],
    icon: "icon-settings",
  },
];

export default sidebarRoutes;
