import styled from "styled-components";

import { StyledButton } from "../../styles/styledButton";

const inputStyleTemplate = (theme) => {
  return `
    width: 100%;
    padding: 10px 16px;
    color: ${theme.neutral.first};
    font-size: 14px;
    background-color: ${theme.neutral.white};
    border: 1px solid ${theme.neutral.five};
    border-radius: 12px;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    transition: all .3s ease;
  
    &:focus, &:active {
      border-color: ${theme.primary.first};
    }
    
    &::placeholder {
      color: ${theme.neutral.third};
    }
    
    &:-webkit-autofill {
      -webkit-text-fill-color: ${theme.neutral.first};
      -webkit-box-shadow: inset 0 0 0 50px ${theme.neutral.white};
    }
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    &:read-only {
      color: ${theme.neutral.third};
    }
    
    &:read-only:focus{
      box-shadow: none;
      background-color: transparent;
    }
    
    @media screen and (max-width: 576px) {
      font-size: 16px;
    }
  `;
};

export const StyledInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  ${({ type }) =>
    type === "hidden" &&
    `
    height: 0;
    margin: 0 !important;
    visibility: hidden;
    opacity: 0;
  `};
`;

export const StyledLabel = styled.label`
  padding-bottom: 8px;
  color: ${({ theme }) => theme.neutral.first};
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  display: flex;
`;

export const StyledTextarea = styled.textarea`
  height: 125px;
  font-family: inherit;
  resize: none;
  border: none;
  outline: none;
  ${({ theme }) => inputStyleTemplate(theme)}
`;

export const StyledInput = styled.input`
  ${({ theme }) => inputStyleTemplate(theme)}
  ${({ showButton }) => showButton && "padding-right: 40px"}
`;

export const StyledDelayInput = styled.div`
  input {
    font-family: inherit;
    ${({ theme }) => inputStyleTemplate(theme)}
  }
`;

export const StyledMaskInput = styled.div`
  input {
    ${({ theme }) => inputStyleTemplate(theme)}
  }
`;

export const StyledInputWrapper = styled.div`
  position: relative;

  ${({ error, theme }) =>
    error &&
    `input, textarea {
      word-break: break-word;
      color: ${theme.danger.first};
      border: 1px solid ${theme.danger.first};
      
      &:focus, &:active {
        border: 1px solid ${theme.danger.first};
      }
    }`};

  ${StyledButton} {
    min-width: 26px;
    min-height: 26px;
    padding: 3px 6px;
    font-size: 12px;
    border-width: 1px;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);

    span {
      font-size: 14px;
    }

    &:disabled,
    &:active {
      transform: scale(0.98) translateY(-50%) translateZ(0) !important;
    }
  }
`;

export const StyledPasswordEye = styled.span`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.neutral.first};
  font-size: 20px;
  position: absolute;
  bottom: 8px;
  right: 16px;
  cursor: pointer;
  opacity: 0.55;

  &:hover {
    opacity: 1;
  }
`;

export const StyledError = styled.div`
  padding-top: 3px;
  color: ${({ theme }) => theme.danger.first};
  font-size: 12px;
  text-align: left;
`;

export const StyledFixedError = styled.div`
  height: 20px;
  color: ${({ theme }) => theme.danger.first};
  font-size: 12px;
  text-align: left;
  line-height: 20px;
  word-break: break-word;
`;

export const StyledDivider = styled.div`
  width: 100%;
  ${({ height }) => (height ? `height: ${height}px` : "height: 1px")};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  background-color: ${({ theme }) => theme.neutral.five};
`;
