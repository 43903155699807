import { TimestampToDateTime } from "../../../../../utils/timestampToDate";
import { StyledBreakWord } from "../../../../styles/StyledBreakWord";
import RenderStatus from "../../../../styles/styledStatus";
import { StyledCol, StyledTableRow } from "../../../../styles/styledTable";

const WithdrawalRequisitionsItem = ({ value, t }) => {
  const { createdAt, id, amount, paymentSystemType, status, comment } = value || {};

  return (
    <StyledTableRow col="6" className="withdrawal-requisitions__row">
      <StyledCol data-title={t("head.id")}>
        <StyledBreakWord>{id}</StyledBreakWord>
      </StyledCol>
      <StyledCol data-title={t("head.status")}>
        <RenderStatus status={status} text={`withdrawalRequisitionStatuses.${status}`} />
      </StyledCol>
      <StyledCol data-title={t("head.amount")}>
        <StyledBreakWord>{amount}</StyledBreakWord>
      </StyledCol>
      <StyledCol data-title={t("head.paymentSystemType")}>
        <StyledBreakWord>{paymentSystemType}</StyledBreakWord>
      </StyledCol>
      <StyledCol data-title={t("head.createdAt")}>{TimestampToDateTime(createdAt)}</StyledCol>
      <StyledCol data-title={t("head.comment")}>
        <StyledBreakWord>{comment || "-"}</StyledBreakWord>
      </StyledCol>
    </StyledTableRow>
  );
};

export default WithdrawalRequisitionsItem;
