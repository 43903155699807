import { createContext, useContext } from "react";

const FilterDataContext = createContext(null);

export const FilterDataProvider = ({ children, filterData, setFilterData }) => {
  const contextValue = {
    filterData,
    setFilterData,
  };

  return <FilterDataContext.Provider value={contextValue}>{children}</FilterDataContext.Provider>;
};

export const useFilterData = () => {
  const context = useContext(FilterDataContext);
  if (!context) {
    throw new Error("useFilterData must be used within a FilterDataProvider");
  }
  return context;
};
