import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { defaultLanguage } from "../../../utils/consts";

const LanguageInitializer = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state.language);

  useEffect(() => {
    const languagePrefix = pathname.match(/^\/(en|ua|ru)/);
    const detectedLang = languagePrefix ? languagePrefix[1] : defaultLanguage;

    //remove default language from url
    if (detectedLang === defaultLanguage && pathname.startsWith("/en")) {
      const newPath = pathname.replace(/^\/en/, "") || "/";
      navigate(newPath, { replace: true });
      return;
    }

    // // undefined language check
    // if (!languagePrefix && currentLanguage !== defaultLanguage) {
    //   dispatch(setLanguage(defaultLanguage));
    //   navigate("/", { replace: true });
    // }
  }, [pathname, currentLanguage, dispatch, navigate]);

  return null;
};

export default LanguageInitializer;
