const basicAuthenticationConfig = (login, password) => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(login + ":" + password),
    },
  };
};

export default basicAuthenticationConfig;
