import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { responseStatus, waitTimer } from "../../utils/consts";
import { errorsParser } from "../../utils/errorsParser";
import { DateToTimestamp } from "../../utils/timestampToDate";
import {
  authenticationRequest,
  getAuthCodeExpiration,
  getEndpointPermissions,
  handleCheckAuthCode,
  handleRegistration,
  handleSetTwoFa,
  recreateAuthCode,
  refreshToken,
} from "./asyncAuthSlice";

const authenticationRequestReducer = (builder) => {
  builder.addCase(authenticationRequest.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(authenticationRequest.fulfilled, (state, action) => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const siteUrl = window.location.origin;

    if (baseURL !== siteUrl) {
      state.isExchangerClient = true;
    }

    if ("token" in action.payload) {
      state.authenticated = true;
      localStorage.setItem("token", action.payload.token);
    }

    if ("userId" in action.payload) {
      state.userTwoFa = true;
      state.userId = action.payload.userId;
    }

    if ("firstLoginData" in action.payload) {
      state.firstLoginData = action.payload.firstLoginData;
    }

    state.loading = false;
  });
  builder.addCase(authenticationRequest.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
    state.errors = action.payload?.errors[0];
  });
};

const handleRegistrationReducer = (builder) => {
  builder.addCase(handleRegistration.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(handleRegistration.fulfilled, (state, action) => {
    state.errors = null;

    const baseURL = process.env.REACT_APP_BASE_URL;
    const siteUrl = window.location.origin;

    if (baseURL !== siteUrl) {
      state.isExchangerClient = true;
    }

    state.userData = action.payload.data[0];
    const user = {
      email: action.meta.arg.data.email,
      password: action.meta.arg.data.password,
    };
    sessionStorage.setItem("userDataTwoFa", JSON.stringify(user));
    closableNotification(action.payload.data[0].success, "success");
    state.userAuthCode = true;
    state.loading = false;
  });
  builder.addCase(handleRegistration.rejected, (state, action) => {
    state.errors = errorsParser(action.payload?.data);
    state.loading = false;

    if (action.payload.status === responseStatus.HTTP_BAD_REQUEST) {
      const key = Object.keys(action.payload.data);
      state.errors[key] = Object.values(action.payload.data);
      closableNotification(action.payload?.data?.data?.errors?.[0], "error");
    }
  });
};

const checkAuthCodeReducer = (builder) => {
  builder.addCase(handleCheckAuthCode.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(handleCheckAuthCode.fulfilled, (state, action) => {
    state.errors = null;
    state.userAuthCode = false;
    state.userTwoFa = true;
    state.loading = false;
    state.firstLoginData = action.payload.data.firstLoginData;
    state.userId = action.payload.data.userId;

    if (action.payload?.data?.token) {
      state.authenticated = true;
      localStorage.setItem("token", action.payload.data.token);
      state.token = action.payload.data.token;
      state.userId = null;
      state.loading = false;
    }
  });
  builder.addCase(handleCheckAuthCode.rejected, (state, action) => {
    if (action.payload?.data?.code === responseStatus.HTTP_CONFLICT) {
      closableNotification(action.payload?.data?.errors[0], "error");
    } else {
      closableNotification(action.payload?.data?.errors?.message, "error");
    }

    state.attempts = action.payload?.data?.errors?.attempts;
    state.loading = false;
  });
};

const handleSetTwoFaReducer = (builder) => {
  builder.addCase(handleSetTwoFa.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(handleSetTwoFa.fulfilled, (state, action) => {
    state.authenticated = true;
    localStorage.setItem("token", action.payload.data.token);
    state.token = action.payload.data.token;
    state.userId = null;
    state.loading = false;
  });
  builder.addCase(handleSetTwoFa.rejected, (state, action) => {
    closableNotification(action.payload?.data?.errors?.[0], "error");
    state.loading = false;
  });
};

const getAuthCodeExpirationReducer = (builder) => {
  builder.addCase(getAuthCodeExpiration.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getAuthCodeExpiration.fulfilled, (state, action) => {
    state.timeDifference =
      waitTimer.MAX - DateToTimestamp(new Date()) + Number(action.payload?.datetime);
    state.codeExpiration =
      waitTimer.CODE_EXPIRED - DateToTimestamp(new Date()) + Number(action.payload?.datetime);
    state.loading = false;
    state.attempts = action.payload?.attempts;
  });
  builder.addCase(getAuthCodeExpiration.rejected, (state, action) => {
    closableNotification(action.payload?.data?.errors?.[0], "error");
    state.loading = false;
  });
};

const recreateAuthCodeReducer = (builder) => {
  builder.addCase(recreateAuthCode.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(recreateAuthCode.fulfilled, (state, action) => {
    state.attempts = waitTimer.ATTEMPTS;
    closableNotification(action.payload.success, "success");
    state.loading = false;
  });
  builder.addCase(recreateAuthCode.rejected, (state, action) => {
    closableNotification(action.payload?.data?.errors?.[0], "error");
    state.loading = false;
  });
};

const refreshTokenReducer = (builder) => {
  builder.addCase(refreshToken.pending, (state) => {
    state.loading = true;
  });

  builder.addCase(refreshToken.fulfilled, (state, action) => {
    if (action.payload?.token) {
      state.authenticated = true;
      localStorage.setItem("token", action.payload.token);
      state.token = action.payload.token;
      state.loading = false;
    }
  });
  builder.addCase(refreshToken.rejected, (state, action) => {
    closableNotification(action.payload?.data?.errors?.[0], "error");
    state.loading = false;
  });
};

const getEndpointPermissionsReducer = (builder) => {
  builder.addCase(getEndpointPermissions.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getEndpointPermissions.fulfilled, (state, action) => {
    if (action.payload?.endpoints) {
      localStorage.setItem("endpoints", JSON.stringify(action.payload));
      state.endpointPermissions = action.payload?.endpoints;
      state.loading = false;
    }
  });
  builder.addCase(getEndpointPermissions.rejected, (state, action) => {
    closableNotification(action.payload?.data?.errors?.[0], "error");
    state.loading = false;
  });
};

export {
  authenticationRequestReducer,
  checkAuthCodeReducer,
  getAuthCodeExpirationReducer,
  getEndpointPermissionsReducer,
  handleRegistrationReducer,
  handleSetTwoFaReducer,
  recreateAuthCodeReducer,
  refreshTokenReducer,
};
