const themeColor = {
  DARK: "dark",
  LIGHT: "light",
};

const defaultLanguage = "en";
const checkSupporterLangWithOutMainLang = ["ua", "ru"];

const candidateRoute = ["/login", "/registration"];

const typeAccountRoutes = {
  ACCOUNT: "/account",
  CRM: "/crm",
};

const currencyTags = {
  CURRENCY: "CURRENCY",
  CRYPTO: "CRYPTO",
};

const invoiceTypes = {
  PAYMENT: "payment",
  PAYOUT: "payout",
};

const fees = {
  CONSTANT: "constant",
  PERCENT: "percent",
  PURCHASE: "purchase",
  SELLING: "selling",
};

const pairStatus = {
  ACTIVE: "Active",
  IN_ACTIVE: "Inactive",
};

const browserLanguages = {
  uk: "ua",
  en: "en",
  "ru-RU": "ru",
  "en-US": "en",
  "uk-UA": "ua",
};

const supportedLanguages = {
  en: {
    url: "",
    routePrefix: "",
    regex: "\\/$|\\b",
    description: "English",
  },
  ua: {
    url: "/ua",
    routePrefix: "ua",
    regex: "\\/ua\\b",
    description: "Українська",
  },
  ru: {
    url: "/ru",
    routePrefix: "ru",
    regex: "\\/ru\\b",
    description: "Русский",
  },
};

const helmetHtmlByLang = {
  en: "en-US",
  ua: "uk-UA",
  ru: "ru-RU",
};

// You can get names and codes of statuses from https://httpstatuses.com/ and add status code to link
const responseStatus = {
  HTTP_OK: 200,
  HTTP_CREATED: 201,
  HTTP_NO_CONTENT: 204,
  HTTP_BAD_REQUEST: 400,
  HTTP_ERROR_VALIDATION: 422,
  FORBIDDEN: 403,
  HTTP_INTERNAL_SERVER_ERROR: 500,
  HTTP_UNAUTHORIZED: 401,
  HTTP_NOT_FOUND: 404,
  HTTP_CONFLICT: 409,
};

const requestStatus = {
  LOADING: "loading",
  RESOLVED: "resolved",
  REJECTED: "rejected",
};

const mainRoles = {
  ADMIN: "ROLE_ADMIN",
  MERCHANT: "ROLE_MERCHANT",
  SYSTEM_ADMIN: "ROLE_SYSTEM_ADMIN",
};

const exchangerRoles = {
  ADMIN: "ROLE_ADMIN",
  USER: "ROLE_USER",
};

const userStatus = {
  ACTIVE: "ACTIVE",
  BANNED: "BANNED",
};

const arrayOperation = {
  FIRST: 0,
};

const regex = {
  EMAIL: "^[_a-z0-9-]+([\\._a-z0-9-])*@[a-z-]+(\\.[a-z-])*(\\.[a-z]{2,})$",
  TEXT: "^[\\'\\ʼ\\`\\-a-zA-Zа-яґіїуєёшщхыърэтьюА-ЯҐІЇУЄЁШЩХЫЪРЭТЬЮ ]+$",
  NAME: "^[\\'\\ʼ\\`\\-a-zA-Zа-яґіїуєёшщхыърэтьюА-ЯҐІЇУЄЁШЩХЫЪРЭТЬЮ0-9]+$",
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!\"#$%&₴€'()*+,-.\/:;<=>?@\[\]^_`{|}~])[a-zA-Z\d!\"#$%&₴€'()*+,-.\/:;<=>?@\[\]^_`{|}~]{8,50}$/,
  CRM_URL: /\/crm\//,
  NUMBER_WITH_DOT: /^[0-9]+(\.[0-9]+)?$/,
  CYRILLIC_SYMBOLS: /[а-яА-ЯЁёҐґІіЇїЄє]/u,
};

const minLength = 10;

const mercureTopicOrigin = "http://localhost";

const mercureUrl = new URL(`${window.location.origin}/.well-known/mercure`);

const timeConsts = {
  DAY: 86400000,
  NORMALIZER: 1000,
};

const logsLevels = {
  INFO: "INFO",
  ERROR: "ERROR",
  WARNING: "WARNING",
};

const pageSkeletonKeys = {
  HOME_PAGE: "/home/HomePage",
  LOGIN_PAGE: "/login/LoginPage",
  REGISTRATION_PAGE: "/registration/RegistrationPage",
};

const direction = {
  PAYMENT: "payment",
  PAYOUT: "payout",
  EXCHANGE: "exchange",
  CASH: "cash",
};

const authFormFieldsLength = {
  FIRST_NAME: 255,
  LAST_NAME: 255,
};

const maxLengthSymbols = {
  AUTH_CODE: 4,
  TWO_FA_CODE: 6,
};

const waitTimer = {
  ZERO: 0,
  MAX: 60,
  CODE_EXPIRED: 600,
  INTERVAL: 1,
  INTERVAL_MS: 1000,
  SECONDS_IN_MINUTE: 60,
  ATTEMPTS: 3,
};

const exchangerStatuses = {
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
  NEW: "NEW",
  DB_CREATED: "DB_CREATED",
  FINISHED: "FINISHED",
};
const databaseExchangerConnectionsStatuses = {
  NEW: "NEW",
  PROCESSED: "PROCESSED",
  REJECTED: "REJECTED",
  DELETE: "DELETE",
};
const orderStatuses = {
  NEW: "NEW",
  WAITING_FOR_PAYMENT: "WAITING_FOR_PAYMENT",
  PAID_IN: "PAID_IN",
  WAITING_FOR_PAYOUT: "WAITING_FOR_PAYOUT",
  PAID_OUT: "PAID_OUT",
  FINISHED: "FINISHED",
  CANCELED: "CANCELED",
  FAIL: "FAIL",
  DISABLED: "DISABLED",
};

const widgetStatuses = {
  NEW: "NEW",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  DISABLED: "DISABLED",
};

const userWidgetStatuses = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
  DISABLED_CREATION: "DISABLED_CREATION",
};

const withdrawalRequisitionStatus = {
  NEW: "NEW",
  FINISHED: "FINISHED",
  CANCELED: "CANCELED",
};

const withdrawalRequisitionFinalStatus = {
  FINISHED: "FINISHED",
  CANCELED: "CANCELED",
};

const orderTypes = {
  BUY: "buy",
  SELL: "sell",
  SWAP: "swap",
};

const defaultItemsPerPage = 30;

const paymentSystemTag = {
  CRYPTO: "CRYPTO",
  CASH: "CASH",
};

const keyboardKeys = {
  ENTER: "Enter",
};

const invoiceStatuses = {
  NEW: "NEW",
  INIT: "INIT",
  FAIL: "FAIL",
  CANCELED: "CANCELED",
  PROCESSED: "PROCESSED",
  PARTIALLY_PROCESSED: "PARTIALLY_PROCESSED",
  PENDING: "PENDING",
  WAITING: "WAITING",
};

const registrationType = {
  GOOGLE: "google",
  FORM: "form",
};

const baseURL = process.env.REACT_APP_BASE_URL;

const siteUrl = window.location.origin;

const defaultRoute =
  baseURL && baseURL !== siteUrl
    ? `${typeAccountRoutes.CRM}/orders`
    : `${typeAccountRoutes.CRM}/exchanger`;

const defaultSystemAdminRoute = `${typeAccountRoutes.CRM}/servers`;

const acceptableFileTypes = ["image/jpeg", "image/jpg", "image/png"];

const fileLoadParams = {
  MAX_AMOUNT: 10,
  MAX_SIZE: 5242880,
  ERROR_SIZE: 0,
  ACCEPTED_TYPES: ["jpg", "png", "jpeg"],
  FAVICON_TYPES: ["ico"],
};

const exchangerSettingsTypes = {
  COLOR: "COLOR",
  INPUT: "INPUT",
  IMAGE: "IMAGE",
};
const mainRolesOptions = [
  { value: mainRoles.ADMIN, label: "Admin" },
  { value: mainRoles.MERCHANT, label: "Merchant" },
  { value: mainRoles.SYSTEM_ADMIN, label: "System Admin" },
];
const exchangerRolesOptions = [
  { value: exchangerRoles.ADMIN, label: "Admin" },
  { value: exchangerRoles.USER, label: "User" },
];
const widgetStatusOption = [
  { label: "widgetStatuses.NEW", value: widgetStatuses.NEW },
  { label: "widgetStatuses.APPROVED", value: widgetStatuses.APPROVED },
  { label: "widgetStatuses.REJECTED", value: widgetStatuses.REJECTED },
  { label: "widgetStatuses.DISABLED", value: widgetStatuses.DISABLED },
];

const userWidgetStatusOption = [
  { label: "userWidgetStatuses.ENABLED", value: userWidgetStatuses.ENABLED },
  { label: "userWidgetStatuses.DISABLED", value: userWidgetStatuses.DISABLED },
  { label: "userWidgetStatuses.DISABLED_CREATION", value: userWidgetStatuses.DISABLED_CREATION },
];

const requisitionAttributes = {
  CARD_NUMBER: "cardNumber",
  CARD_COUNTRY: "cardCountry",
  CARD_HOLDER: "cardHolder",
  RECIPIENT_NAME: "recipientName",
  CARD_COUNTRY_DOB: "cardHolderDOB",
  CARD_COUNTRY_EXP_MONTH: "cardExpMonth",
  CARD_COUNTRY_EXP_YEAR: "cardExpYear",
  CONTACTS: "contacts",
  TAG: "tag",
  WALLET: "wallet",
  PURSE: "purse",
  EMAIL: "email",
  ACCOUNT_PURSE: "accountPurse",
  TYPE_HIDDEN: "hidden",
  IBAN: "iban",
  PAYER_CARD_NUMBER: "payerCardNumber",
  RECIPIENT: "recipient",
  RECIPIENT_ADDRESS: "recipientAddress",
  SWIFT_CODE: "swiftCode",
  RECEIVER_BIRTH_DATE: "receiverBirthDate",
  RECEIVER_COUNTRY: "receiverCountry",
  RECEIVER_FIO: "receiverFio",
  CARD_EXPIRED: "cardExpire",
  PHONE_NUMBER: "phoneNumber",
  RECEIVER_CITY: "receiverCity",
  CITY_ID: "cityId",
};

const requisitionsAttributesValues = {
  MAX_NAME_LENGTH: 50,
  MAX_NAME_WORDS_AMOUNT: 2,
};

const widgetReports = {
  WIDGET_CLICKS: "widgetClicks",
  WIDGET_PAGE_VISIT_LIFE: 21600000,
};

export {
  acceptableFileTypes,
  arrayOperation,
  authFormFieldsLength,
  baseURL,
  browserLanguages,
  candidateRoute,
  checkSupporterLangWithOutMainLang,
  currencyTags,
  databaseExchangerConnectionsStatuses,
  defaultItemsPerPage,
  defaultLanguage,
  defaultRoute,
  defaultSystemAdminRoute,
  direction,
  exchangerRoles,
  exchangerRolesOptions,
  exchangerSettingsTypes,
  exchangerStatuses,
  fees,
  fileLoadParams,
  helmetHtmlByLang,
  invoiceStatuses,
  invoiceTypes,
  keyboardKeys,
  logsLevels,
  mainRoles,
  mainRolesOptions,
  maxLengthSymbols,
  mercureTopicOrigin,
  mercureUrl,
  minLength,
  orderStatuses,
  orderTypes,
  pageSkeletonKeys,
  pairStatus,
  paymentSystemTag,
  regex,
  registrationType,
  requestStatus,
  requisitionAttributes,
  requisitionsAttributesValues,
  responseStatus,
  siteUrl,
  supportedLanguages,
  themeColor,
  timeConsts,
  typeAccountRoutes,
  userStatus,
  userWidgetStatusOption,
  waitTimer,
  widgetReports,
  widgetStatuses,
  widgetStatusOption,
  withdrawalRequisitionFinalStatus,
  withdrawalRequisitionStatus,
};
