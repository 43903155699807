import { useTranslation } from "react-i18next";

import AlertMessage from "../../../../elements/alert/Alert";
import Spinner from "../../../../elements/spinner/Spinner";
import {
  StyledScrollTable,
  StyledTable,
  StyledTableBody,
  StyledTableHead,
} from "../../../../styles/styledTable";
import WithdrawalRequisitionsItem from "./WithdrawalRequisitionsItem";

const WithdrawalRequisitionsList = ({ loading, list }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "clientDetail.withdrawalRequisitionsTab.table",
  });
  const { t: tAlerts } = useTranslation("translation", { keyPrefix: "alerts" });

  if (loading) {
    return <Spinner size="35px" />;
  }

  if (!list || list.length === 0) {
    return <AlertMessage type="warning" message={tAlerts("emptyData")} />;
  }

  return (
    <StyledScrollTable>
      <StyledTable className="withdrawal-requisitions" width="1330">
        <StyledTableHead col="6" className="withdrawal-requisitions__head">
          <div>{t("head.id")}</div>
          <div>{t("head.status")}</div>
          <div>{t("head.amount")}</div>
          <div>{t("head.paymentSystemType")}</div>
          <div>{t("head.createdAt")}</div>
          <div>{t("head.comment")}</div>
        </StyledTableHead>
        <StyledTableBody>
          {list.map((value, key) => (
            <WithdrawalRequisitionsItem value={value} key={key} t={t} />
          ))}
        </StyledTableBody>
      </StyledTable>
    </StyledScrollTable>
  );
};

export default WithdrawalRequisitionsList;
