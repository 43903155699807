import CustomLink from "../elements/customLink/CustomLink";

const BalanceButton = ({ loading, totalAmount }) => {
  return (
    <CustomLink className="header-right__balance" to={"/crm/balance"}>
      <span className="icon-usdt" />
      {loading || !totalAmount ? "0.00" : (+totalAmount).toFixed(2)}
    </CustomLink>
  );
};

export default BalanceButton;
