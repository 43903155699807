import styled from "styled-components";

export const StyledCustomCopyToClipboard = styled.div`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ mr }) => mr && `margin-right: ${mr}px`};
  ${({ ml }) => ml && `margin-left: ${ml}px`};
  ${({ maxWidth }) => maxWidth && `width: ${maxWidth}px`};
  ${({ display }) => display && `display: ${display}`};
  ${({ border }) =>
    !border &&
    `
    width: 100%;
    overflow-wrap: break-word;
  `}
  cursor: ${({ cursor }) => (cursor ? cursor : "unset")};
`;
export const StyledCopyIconAndTextBlock = styled.p`
  font-size: 14px;
  display: flex;
  gap: 2px;
  align-items: center;
  border-radius: 12px;
  transition: all 0.3s ease;

  ${({ border, theme }) =>
    border &&
    `
    padding: 5px 10px;
    border: 1px solid ${theme.neutral.six};
  `}

  ${({ border, theme }) =>
    !border &&
    `
    .ellipses {
      width: 100%;
      white-space: normal;
      overflow-wrap: break-word;  
    }
    
    .icon-copy {
      padding-left: 2px;
    }
    
    &:hover {
      color: ${theme.neutral.black};
    }
  `}
  
  &:hover {
    border-color: ${({ theme }) => theme.primary.first};
  }

  .icon-copy {
    font-size: 18px;
    color: ${({ theme }) => theme.primary.first};
    transition: all 0.3s ease;

    &:hover {
      color: ${({ theme }) => theme.primary.second};
    }
  }
`;
