import { t } from "i18next";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../utils/errorsParser";
import {
  fetchExchangerConnections,
  processExchangerConnectionStatus,
} from "./asyncDbExchangerConnectionsSlice";

const fetchExchangerConnectionsReducer = (builder) => {
  builder.addCase(fetchExchangerConnections.fulfilled, (state, action) => {
    state.exchangerConnections = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchExchangerConnections.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const changeExchangerConnectionsStatusReducer = (builder) => {
  builder.addCase(processExchangerConnectionStatus.fulfilled, (state, action) => {
    const newObjectExchanger = action.payload.data;
    state.exchangerConnections = state.exchangerConnections.map((item) => {
      if (item.id === newObjectExchanger.id) {
        return newObjectExchanger;
      }

      return item;
    });
    state.errors = null;
    closableNotification(t("alerts.connectionStatusUpdated"), "success");
  });
  builder.addCase(processExchangerConnectionStatus.rejected, (state, action) => {
    errorsParser(action.payload);
  });
};

export { changeExchangerConnectionsStatusReducer, fetchExchangerConnectionsReducer };
