import styled from "styled-components";

import { StyledMobileNavigation } from "../../elements/navigation/styledNavigation";
import { StyledContainer } from "../../styles/styledContainer";

export const StyledHeaderWrapper = styled.header`
  padding: 12px 24px;
  width: 100%;
  height: 60px;
  color: ${({ theme }) => theme.neutral.white};
  background-color: ${({ theme }) => theme.headerBackgroundColor};
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  @media screen and (max-width: 992px) {
    height: 50px;
    padding: 10px 16px;
  }
`;

export const StyledHeaderContainer = styled(StyledContainer)`
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
  position: static;
  overflow-x: visible;

  .header-left,
  .header-right {
    max-width: 220px;
    width: 100%;
    display: inline-flex;
    align-items: center;

    &__collapse {
      margin-left: 5px;
      padding: 5px;
      color: ${({ theme }) => theme.neutral.white};
      display: inline-flex;
      align-items: center;
      border: none;
      outline: none;

      span {
        width: 20px;
        height: 20px;
        font-size: 20px;
        display: inline-flex;
        align-items: center;
        opacity: 0.5;
        transition: all 0.3s ease;
      }

      &:hover span {
        opacity: 1;
      }
    }
  }

  .header-right {
    justify-content: flex-end;

    &__account {
      transition: all 0.3s ease;

      .icon-login {
        padding-top: 2px;
        font-size: 25px;
        display: none;
      }

      &:hover {
        color: ${({ theme }) => theme.primary.first};
      }
    }
    &__balance {
      display: flex;
      gap: 6px;
      align-items: center;
      margin-right: 16px;
      &:hover {
        color: ${({ theme }) => theme.primary.first};
      }
    }
  }

  @media screen and (max-width: 992px) {
    .header-left,
    .header-right {
      &__collapse {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    //display: grid;
    //grid-template-columns: 1fr repeat(2, max-content);
    //.header-left,
    //.header-right {
    //  &__account {
    //    display: none;
    //  }
    //}

    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    background-color: transparent;
    border: none;
    position: static;
    overflow-x: visible;

    .header-left {
      //order: 2;
      //width: 100%;
      //display: inline-flex;
      //align-items: center;
    }

    .header-right {
      //order: 3;

      &__account {
        &-title {
          display: none;
        }

        .icon-login {
          display: block;
        }
      }
    }
  }
`;

export const StyledMobileSideBarIcon = styled(StyledMobileNavigation)`
  @media screen and (max-width: 992px) {
    display: block;
  }
`;
