import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  authenticationRequest,
  getEndpointPermissions,
  handleRegistration,
} from "../../store/auth/asyncAuthSlice";
import { removeAuthErrors, setAuthErrors } from "../../store/auth/authSlice";
import { authentication } from "../../utils/authenticationRequest";
import { authFormFieldsLength, helmetHtmlByLang, regex } from "../../utils/consts";
import { getLocalizedLink } from "../../utils/customFunc/getLocalizedLink";
import { closableNotification } from "../elements/notification/ClosableNotification";
import { StyledContainer } from "../styles/styledContainer";
import RegistrationFormStep from "./registrationSteps/RegistationFormStep";
import RegistrationTwoFaForm from "./registrationSteps/RegistrationTwoFaFormStep";
import { StyledRegistrationWrapper } from "./styledRegistration";

const Registration = ({ t }) => {
  const navigate = useNavigate();
  const [{ firstname, lastname, email, password }, setClientDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });
  const [agree, setAgree] = useState(false);
  const [authData, setAuthData] = useState({
    headers: null,
    body: null,
  });

  const dispatch = useDispatch();

  const authState = useSelector((store) => store.auth);

  const siteConfigState = useSelector((state) => state.theme);

  const {
    authenticated,
    loading,
    userTwoFa,
    errors,
    userAuthCode,
    firstLoginData,
    userId,
    endpointPermissions,
  } = authState;

  const { siteConfig } = siteConfigState;

  useEffect(() => {
    if (!authData.headers) {
      return;
    }

    dispatch(authenticationRequest(authData));
  }, [authData]);

  useEffect(() => {
    if (authenticated) {
      dispatch(getEndpointPermissions()).then(() => {
        authentication(navigate, authenticated, dispatch);
      });
    }
  }, [authenticated]);

  const callbackAfterRegister = (userId) => {
    navigate(getLocalizedLink(`/email-verification/${userId}`));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let data = {
      email: event.target.email.value.trim(),
      firstname: event.target.firstname.value.trim(),
      lastname: event.target.lastname.value.trim(),
      password: event.target.password.value.trim(),
    };

    if (!data.firstname.match(regex.NAME)) {
      dispatch(setAuthErrors({ firstname: "The Name field contains invalid symbols" }));
      return false;
    }

    if (!data.lastname.match(regex.NAME)) {
      dispatch(setAuthErrors({ lastname: "Last name field contains invalid symbols" }));
      return false;
    }

    if (data.password.length < 8 || data.password.length > 50) {
      dispatch(setAuthErrors({ password: "Minimum 8 and maximum 50 Latin characters" }));
      return false;
    }

    if (!data.password.match(regex.PASSWORD)) {
      dispatch(setAuthErrors({ password: "Password field contains invalid symbols" }));
      return false;
    }

    dispatch(handleRegistration({ data, callback: callbackAfterRegister }));
  };

  const onChangeInput = (event) => {
    const { name, value } = event.target;

    if (
      (name === "firstname" || name === "lastname") &&
      value.length > authFormFieldsLength.FIRST_NAME
    ) {
      closableNotification(`The ${name} field contains more then 255 symbols`, "error");

      return;
    }

    setClientDetails((prevState) => ({ ...prevState, [name]: value }));

    dispatch(removeAuthErrors(name));
  };

  return (
    <StyledContainer>
      <Helmet>
        <title>{t("registration.meta.title", { title: siteConfig.metaTitle })}</title>
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/registration"}
          hrefLang={helmetHtmlByLang.en}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ua/registration"}
          hrefLang={helmetHtmlByLang.ua}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ru/registration"}
          hrefLang={helmetHtmlByLang.ru}
        />
      </Helmet>
      <StyledRegistrationWrapper>
        {!userAuthCode && !userTwoFa && (
          <RegistrationFormStep
            handleSubmit={handleSubmit}
            onChangeInput={onChangeInput}
            agree={agree}
            setAgree={setAgree}
            errors={errors}
            firstname={firstname}
            lastname={lastname}
            email={email}
            password={password}
            loading={loading}
            t={t}
            authData={authData}
            setAuthData={setAuthData}
          />
        )}
        {(firstLoginData || userId) && (
          <>
            <RegistrationTwoFaForm
              loading={loading}
              authData={authData}
              setAuthData={setAuthData}
              t={t}
            />
          </>
        )}
      </StyledRegistrationWrapper>
    </StyledContainer>
  );
};

export default withTranslation("translation", { keyPrefix: "auth" })(Registration);
