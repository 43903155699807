import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchReferralToken = createAsyncThunk(
  "referralSystem/fetchReferralToken",
  async (_, thunkAPI) => {
    const url = "/api/exchanger-referral-info/token";

    try {
      const response = await axiosInstance.get(url, userAuthenticationConfig());

      return {
        data: response.data.refToken,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchReferralUsers = createAsyncThunk(
  "referralSystem/fetchReferralUsers",
  async ({ filterUrl }, thunkAPI) => {
    const url = `/api/exchanger-users/referral-users${filterUrl}`;

    try {
      const response = await axiosInstance.get(url, userAuthenticationConfig());

      return {
        data: response.data.referrals,
        paginationInfo: {
          totalPageCount: response.data.totalPageCount,
          totalItems: response.data.totalItems,
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export { fetchReferralToken, fetchReferralUsers };
