import { errorsParser } from "../../utils/errorsParser";
import { getProfitsInfo, getReportsInfo } from "./asyncReposts";

const getReportsInfoReducer = (builder) => {
  builder.addCase(getReportsInfo.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getReportsInfo.fulfilled, (state, action) => {
    state.reportsInfo = action.payload.data.reportsInfo;
    state.loading = false;
  });
  builder.addCase(getReportsInfo.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

export { getReportsInfoReducer };
