import { createSlice } from "@reduxjs/toolkit";

import { getReportsInfoReducer } from "./reportsReducer";

const initialState = {
  reportsInfo: {},
  loading: true,
  errors: null,
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    clearStateReports: (state) => {
      state.reportsInfo = {};
      state.loading = true;
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    getReportsInfoReducer(builder);
  },
});

export const { clearStateReports } = reportsSlice.actions;

export default reportsSlice.reducer;
