import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getExpirationDate, isExpired } from "../checkExpiredToken";
import eventBus from "../eventBus";

export const useAuthenticationGuard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    const expToken = isExpired(getExpirationDate(token));

    if (expToken) {
      eventBus.dispatch("logout", { expired: true });
      //navigate("/login");
    }
  }, [location.pathname, navigate]);
};
