import Select, { Option } from "rc-select";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { setLanguage } from "../../../store/language/languageSlice";
import { defaultLanguage, supportedLanguages } from "../../../utils/consts";
import { StyledLanguageSelect } from "./styledLanguageSelect";

const regex = new RegExp(`^/(${Object.keys(supportedLanguages).join("|")})/`);

const LanguageSelect = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { locale } = useParams();
  const actualLang = useSelector((state) => state.language);

  const replacePathName = pathname.replace(regex, "/");

  const handleChangeLang = (value) => {
    dispatch(setLanguage(value));

    const newPath =
      value === defaultLanguage ? pathname.replace(regex, "/") : `/${value}${replacePathName}`;

    if (window.location.search.length > 0) {
      navigate(newPath + window.location.search, { replace: true });
    } else {
      navigate(newPath, { replace: true });
    }
  };

  useEffect(() => {
    if (!!locale && !Object.keys(supportedLanguages).some((lang) => lang === locale)) {
      const newPath = pathname.replace(`/${locale}/`, "/");
      navigate(newPath);

      dispatch(setLanguage(defaultLanguage));
    }
  }, [locale]);

  // const getUrlLanguage = (value) => {
  //   return (
  //     value.split("/").find((itemLNG) => {
  //       if (checkSupporterLangWithOutMainLang.includes(itemLNG)) {
  //         return itemLNG;
  //       }
  //     }) || defaultLanguage
  //   );
  // };
  // useEffect(() => {
  //   const path = window.location.href.split(window.location.host)[1];
  //   const urlLanguage = getUrlLanguage(path);
  //   if (actualLang !== urlLanguage) {
  //     const newPath =
  //       actualLang === defaultLanguage
  //         ? pathname.replace(regex, "/")
  //         : `/${actualLang}${replacePathName}`;
  //
  //     if (window.location.search.length > 0) {
  //       navigate(newPath + window.location.search, { replace: true });
  //     } else {
  //       navigate(newPath, { replace: true });
  //     }
  //   }
  //
  //   if (
  //     actualLang === defaultLanguage &&
  //     window.location.pathname.includes(`/${defaultLanguage}/`)
  //   ) {
  //     const compareUrl = path.split(defaultLanguage)[1];
  //     navigate(compareUrl, { replace: true });
  //   }
  //
  //   if (
  //     actualLang !== defaultLanguage &&
  //     !Object.keys(supportedLanguages).some((item) => window.location.pathname.includes(`/${item}`))
  //   ) {
  //     const compareUrl = "/" + actualLang + path;
  //     navigate(compareUrl, { replace: true });
  //   }
  // }, [pathname, actualLang, dispatch, navigate]);

  return (
    <StyledLanguageSelect id="lang" className="lang-select-wrapper">
      <Select
        className="custom-select lang-select"
        name="lang"
        value={actualLang}
        onChange={(value) => handleChangeLang(value)}
        getPopupContainer={() => document.getElementById("lang")}
        dropdownMatchSelectWidth={false}
        direction="rtl"
      >
        {Object.entries(supportedLanguages).map(([key, value]) => {
          return (
            <Option value={key} key={key} className={key === actualLang ? "active" : ""}>
              <div className="option-select-item lang-select__item">
                <div className="lang-select__pref">{key.toUpperCase()}</div>
                <div className="lang-select__text">{value.description}</div>
              </div>
            </Option>
          );
        })}
      </Select>
    </StyledLanguageSelect>
  );
};

export default LanguageSelect;
