import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Trans, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { authenticationRequest, getEndpointPermissions } from "../../store/auth/asyncAuthSlice";
import { authentication } from "../../utils/authenticationRequest";
import { helmetHtmlByLang } from "../../utils/consts";
import CustomLink from "../elements/customLink/CustomLink";
import { StyledContainer } from "../styles/styledContainer";
import { StyledMainTitle } from "../styles/styledTitle";
import LoginForm from "./LoginForm";
import LoginGoogleForm from "./LoginGoogleForm";
import LoginTwoFaForm from "./LoginTwoFaForm";
import { StyledAlternativeText, StyledAuthText, StyledLoginWrapper } from "./styledLogin";

const Login = ({ t }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const authState = useSelector((store) => store.auth);

  const siteConfigState = useSelector((state) => state.theme);

  const { authenticated, loading, userTwoFa, endpointPermissions } = authState;

  const { siteConfig } = siteConfigState;

  const [authData, setAuthData] = useState({
    headers: null,
    body: null,
  });

  useEffect(() => {
    if (!authData.headers) {
      return;
    }

    dispatch(authenticationRequest(authData));
  }, [authData]);

  useEffect(() => {
    if (authenticated) {
      dispatch(getEndpointPermissions()).then(() => {
        authentication(navigate, authenticated, dispatch);
      });
    }
  }, [authenticated]);

  return (
    <StyledContainer>
      <Helmet>
        <title>{t("login.meta.title", { title: siteConfig.metaTitle })}</title>
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/login"}
          hrefLang={helmetHtmlByLang.en}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ua/login"}
          hrefLang={helmetHtmlByLang.ua}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ru/login"}
          hrefLang={helmetHtmlByLang.ru}
        />
      </Helmet>
      <StyledLoginWrapper>
        {!userTwoFa ? (
          <>
            <StyledMainTitle mb="40" size="22" position="center">
              {t("login.title")}
            </StyledMainTitle>
            <LoginForm loading={loading} authData={authData} setAuthData={setAuthData} t={t} />

            <StyledAlternativeText>{t("login.description.useEmail")}</StyledAlternativeText>
            <LoginGoogleForm authData={authData} setAuthData={setAuthData} t={t} />

            <StyledAuthText className="register-now-text">
              <Trans
                components={{
                  CustomLink: <CustomLink className="default-link" to="/registration" />,
                }}
                t={t}
                i18nKey="login.description.createAccount"
              />
            </StyledAuthText>
          </>
        ) : (
          <LoginTwoFaForm loading={loading} authData={authData} setAuthData={setAuthData} t={t} />
        )}
      </StyledLoginWrapper>
    </StyledContainer>
  );
};

export default withTranslation("translation", { keyPrefix: "auth" })(Login);
