import { t } from "i18next";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../utils/errorsParser";
import {
  createSavedRequisites,
  deleteSavedRequisites,
  fetchPairUnits,
  fetchSavedRequisites,
  updateSavedRequisites,
} from "./asyncSavedRequisites";

const getSavedRequisitesReducer = (builder) => {
  builder.addCase(fetchSavedRequisites.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchSavedRequisites.fulfilled, (state, action) => {
    state.savedRequisites = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchSavedRequisites.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const updateSavedRequisitesReducer = (builder) => {
  builder.addCase(updateSavedRequisites.pending, (state) => {
    state.updateSavedRequisitesLoading = true;
  });
  builder.addCase(updateSavedRequisites.fulfilled, (state, action) => {
    closableNotification(t("alerts.savedRequisitesUpdated"), "success");
    state.savedRequisites = state.savedRequisites.map((item) => {
      if (item.id === action.payload.data.id) {
        return action.payload.data;
      }

      return item;
    });
    state.updateSavedRequisitesLoading = false;
  });
  builder.addCase(updateSavedRequisites.rejected, (state, action) => {
    errorsParser(action.payload);
    state.updateSavedRequisitesLoading = false;
  });
};

const deleteSavedRequisitesReducer = (builder) => {
  builder.addCase(deleteSavedRequisites.fulfilled, (state, action) => {
    closableNotification(t("alerts.savedRequisitesDeleted"), "success");
    state.savedRequisites = state.savedRequisites.filter((item) => item.id !== action.payload.id);
    state.loading = false;
  });
  builder.addCase(deleteSavedRequisites.rejected, (state, action) => {
    errorsParser(action.payload);
  });
};

const getPairUnitsReducer = (builder) => {
  builder.addCase(fetchPairUnits.fulfilled, (state, action) => {
    state.pairUnits = action.payload.data;
  });
  builder.addCase(fetchPairUnits.rejected, (state, action) => {
    errorsParser(action.payload);
  });
};

const createSavedRequisitesReducer = (builder) => {
  builder.addCase(createSavedRequisites.pending, (state) => {
    state.createSavedRequisitesLoading = true;
  });
  builder.addCase(createSavedRequisites.fulfilled, (state, action) => {
    closableNotification(t("alerts.savedRequisitesCreated"), "success");
    state.savedRequisites = [action.payload.data, ...state.savedRequisites];
    state.createSavedRequisitesLoading = false;
  });
  builder.addCase(createSavedRequisites.rejected, (state, action) => {
    errorsParser(action.payload);
    state.createSavedRequisitesLoading = false;
  });
};

export {
  createSavedRequisitesReducer,
  deleteSavedRequisitesReducer,
  getPairUnitsReducer,
  getSavedRequisitesReducer,
  updateSavedRequisitesReducer,
};
