import styled from "styled-components";

export const StyledTwoFaForm = styled.form`
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  .twofa-input input {
    text-align: center;
    font-weight: 600;
    letter-spacing: 5px;
    padding: 14px 16px;
  }

  .insert-button {
    right: 16px;
  }
`;

export const StyledTwoFaActive = styled.div`
  .security-code {
    text-align: center;
  }
`;

export const StyledTwoFaActiveDescription = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.neutral.five};
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

export const StyledFirstLoginDataWrapper = styled.div`
  max-width: 420px;
  width: 100%;
  margin: 0 auto 24px;
  padding-bottom: 24px;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  & > div {
    display: inline-block;
  }

  .copy-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .copy-text {
    color: ${(props) => props.theme.primary.first};
    font-size: 28px;
    font-weight: 600;
    line-height: 100%;
  }

  img {
    margin: 0 auto;
    display: block;
  }
`;
