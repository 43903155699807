import Tooltip from "rc-tooltip";
import { useEffect } from "react";
import { withTranslation } from "react-i18next";

import { regex } from "../../../utils/consts";
import {
  StyledPasswordValidatorItem,
  StyledPasswordValidatorWrapper,
} from "./styledPasswordValidator";

const PasswordValidator = ({ password, passwordErrors, setPasswordErrors, dark, t }) => {
  const validate = () => {
    let errors = {
      isLengthValid: false,
      hasUppercase: false,
      hasLowercase: false,
      hasNumber: false,
      hasSpecChar: false,
    };

    if (password.length >= 8 && password.length <= 50) {
      errors.isLengthValid = true;
    }

    if (/[A-Z]/.test(password)) {
      errors.hasUppercase = true;
    }

    if (/[a-z]/.test(password)) {
      errors.hasLowercase = true;
    }

    if (/[0-9]/.test(password)) {
      errors.hasNumber = true;
    }

    if (/[!"#$%&'()*+,-.\/:;<=>?@\[\]^_`{|}~]/.test(password)) {
      errors.hasSpecChar = true;
    }

    if (regex.CYRILLIC_SYMBOLS.test(password)) {
      errors.isLengthValid = false;
    }

    setPasswordErrors(errors);
  };

  useEffect(() => {
    validate();
  }, [password]);

  return (
    <StyledPasswordValidatorWrapper>
      <StyledPasswordValidatorItem
        isValid={!password ? null : !!passwordErrors?.isLengthValid}
        dark={dark}
      >
        <Tooltip
          placement="bottom"
          overlay={t("passwordValidator.isLengthValid.description")}
          overlayClassName="password-tooltip"
        >
          <div>{t("passwordValidator.isLengthValid.title")}</div>
        </Tooltip>
      </StyledPasswordValidatorItem>
      <StyledPasswordValidatorItem
        isValid={!password ? null : !!passwordErrors?.hasUppercase}
        dark={dark}
      >
        <Tooltip
          placement="bottom"
          overlay={t("passwordValidator.hasUppercase.description")}
          overlayClassName="password-tooltip"
        >
          <div>{t("passwordValidator.hasUppercase.title")}</div>
        </Tooltip>
      </StyledPasswordValidatorItem>
      <StyledPasswordValidatorItem
        isValid={!password ? null : !!passwordErrors?.hasLowercase}
        dark={dark}
      >
        <Tooltip
          placement="bottom"
          overlay={t("passwordValidator.hasLowercase.description")}
          overlayClassName="password-tooltip"
        >
          <div>{t("passwordValidator.hasLowercase.title")}</div>
        </Tooltip>
      </StyledPasswordValidatorItem>
      <StyledPasswordValidatorItem
        isValid={!password ? null : !!passwordErrors?.hasNumber}
        dark={dark}
      >
        <Tooltip
          placement="bottom"
          overlay={t("passwordValidator.hasNumber.description")}
          overlayClassName="password-tooltip"
        >
          <div>{t("passwordValidator.hasNumber.title")}</div>
        </Tooltip>
      </StyledPasswordValidatorItem>
      <StyledPasswordValidatorItem
        isValid={!password ? null : !!passwordErrors?.hasSpecChar}
        dark={dark}
      >
        <Tooltip
          placement="bottom"
          overlay={t("passwordValidator.hasSpecChar.description")}
          overlayClassName="password-tooltip"
        >
          <div>{t("passwordValidator.hasSpecChar.title")}</div>
        </Tooltip>
      </StyledPasswordValidatorItem>
    </StyledPasswordValidatorWrapper>
  );
};

export default withTranslation("translation", { keyPrefix: "auth" })(PasswordValidator);
