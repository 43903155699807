import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

const status = {
  status: String,
};

const changeStatus = (status, theme) => {
  switch (status) {
    case "ERROR":
    case "DISABLED":
    case "CANCELED":
    case "REJECTED":
    case "FAIL":
    case "DISABLED_CREATION":
    case "BANNED":
    case "NOT VERIFIED":
      return `
        color: ${theme.danger.first};
        background: ${theme.danger.second};
      `;
    case "DELETED":
      return `
        color: ${theme.danger.first};
        background: ${theme.danger.second};
      `;
    case "DB_CREATED":
    case "WAITING_FOR_PAYMENT":
    case "PAID_IN":
    case "WAITING_FOR_PAYOUT":
    case "PAID_OUT":
    case "PENDING":
    case "PARTIALLY_PROCESSED":
    case "WAITING":
      return `
        color: ${theme.warning.first};
        background: ${theme.warning.second};
      `;
    case "FINISHED":
    case "PROCESSED":
    case "APPROVED":
    case "ENABLED":
    case "VERIFIED":
      return `
        color: ${theme.success.first};
        background: ${theme.success.second};
      `;
    default:
      return `
        color: ${theme.info.first};
        background: ${theme.info.second};
      `;
  }
};

export const StyledStatus = styled("span", status)`
  padding: 4px 8px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  border-radius: 12px;
  display: inline-grid;
  ${({ status, theme }) => changeStatus(status, theme)};
`;

const RenderStatus = ({ t, status, text = null }) => {
  return (
    <StyledStatus status={status}>
      {text && typeof text === "object" ? t(text.key, text.params) : t(text || status)}
    </StyledStatus>
  );
};

export default withTranslation("translation", { keyPrefix: "statuses" })(RenderStatus);
