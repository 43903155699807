import { createSlice } from "@reduxjs/toolkit";

import { defaultItemsPerPage } from "../../utils/consts";
import {
  createSavedRequisitesReducer,
  deleteSavedRequisitesReducer,
  getPairUnitsReducer,
  getSavedRequisitesReducer,
  updateSavedRequisitesReducer,
} from "./savedRequisitesReducer";

const initialState = {
  savedRequisites: [],
  errors: null,
  loading: true,
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: defaultItemsPerPage,
  },
  updateSavedRequisitesLoading: false,
  pairUnits: [],
  createSavedRequisitesLoading: false,
};

const savedRequisitesSlice = createSlice({
  name: "savedRequisites",
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearStateSavedRequisites: (state) => {
      state.savedRequisites = [];
      state.loading = true;
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    getSavedRequisitesReducer(builder);
    updateSavedRequisitesReducer(builder);
    deleteSavedRequisitesReducer(builder);
    getPairUnitsReducer(builder);
    createSavedRequisitesReducer(builder);
  },
});

export const { setPaginationInfo, clearStateSavedRequisites } = savedRequisitesSlice.actions;

export default savedRequisitesSlice.reducer;
