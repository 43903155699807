import Checkbox from "rc-checkbox";
import { useState } from "react";
import { Trans } from "react-i18next";

import { regex } from "../../../utils/consts";
import CustomLink from "../../elements/customLink/CustomLink";
import InputGroup from "../../elements/inputGroup/InputGroup";
import PasswordValidator from "../../elements/password/PasswordValidator";
import LoadButton from "../../elements/spinner/ButtonSpinner";
import LoginGoogleForm from "../../login/LoginGoogleForm";
import { StyledAlternativeText, StyledAuthText } from "../../login/styledLogin";
import { StyledButton } from "../../styles/styledButton";
import { StyledCheckboxLabel, StyledCheckboxWrapper } from "../../styles/styledCheckbox";
import { StyledMainTitle } from "../../styles/styledTitle";
import { StyledRegistrationForm } from "../styledRegistration";

const RegistrationFormStep = ({
  handleSubmit,
  onChangeInput,
  agree,
  setAgree,
  errors,
  firstname,
  lastname,
  email,
  password,
  loading,
  t,
  authData,
  setAuthData,
}) => {
  const [passwordErrors, setPasswordErrors] = useState(null);

  return (
    <>
      <StyledMainTitle mb="40" size="22" position="center">
        {t("registration.title")}
      </StyledMainTitle>
      {/*<StyledAuthText size="12">*/}
      {/*  {t("registration.description")}*/}
      {/*</StyledAuthText>*/}
      <StyledRegistrationForm>
        <form onSubmit={handleSubmit}>
          <InputGroup
            id="firstname"
            type="text"
            label={t("registration.form.firstname")}
            placeholder={t("registration.form.firstnamePlaceholder")}
            name="firstname"
            data-regex={regex.NAME}
            autoComplete="off"
            onChange={onChangeInput}
            required
            error={errors?.["firstname"]}
            value={firstname}
            maxLength={100}
          />
          <InputGroup
            id="lastname"
            type="text"
            label={t("registration.form.lastname")}
            placeholder={t("registration.form.lastnamePlaceholder")}
            data-regex={regex.NAME}
            name="lastname"
            autoComplete="off"
            required
            error={errors?.["lastname"]}
            onChange={onChangeInput}
            value={lastname}
            maxLength={100}
          />
          <InputGroup
            id="email"
            type="email"
            label="E-mail"
            placeholder={t("registration.form.emailPlaceholder")}
            name="email"
            autoComplete="off"
            data-regex={regex.EMAIL}
            required
            error={errors?.["email"]}
            onChange={onChangeInput}
            value={email}
            maxLength={150}
          />
          <InputGroup
            id="password"
            type="password"
            label="Password"
            placeholder="Enter your password"
            name="password"
            autoComplete="off"
            data-regex={regex.PASSWORD}
            required
            error={errors?.["password"]}
            handleChange={onChangeInput}
            value={password}
          />
          <PasswordValidator
            password={password}
            passwordErrors={passwordErrors}
            setPasswordErrors={setPasswordErrors}
            t={t}
          />
          <StyledAuthText size="12">
            <StyledCheckboxWrapper>
              <Checkbox
                id="agree"
                className="default-checkbox"
                onClick={() => setAgree(!agree)}
                name="agree"
                value={agree}
                required="required"
                autoComplete="off"
                checked={agree}
              />
              <StyledCheckboxLabel htmlFor="agree" padding="0 8px 0">
                <Trans
                  components={{
                    termsAndConditions: <a href="#" className="default-link" target="_blank" />,
                    privacyPolicy: <a href="#" className="default-link" target="_blank" />,
                  }}
                  t={t}
                  i18nKey="registration.confirm"
                />
              </StyledCheckboxLabel>
            </StyledCheckboxWrapper>
          </StyledAuthText>
          <div className="registration-action">
            {!loading ? (
              <StyledButton weight="600" color="main" type="submit" width="100%" disabled={!agree}>
                {t("registration.form.submit")}
              </StyledButton>
            ) : (
              <LoadButton
                width="100%"
                weight="600"
                color="main"
                text={t("registration.form.submit")}
              />
            )}
          </div>
        </form>
      </StyledRegistrationForm>
      <StyledAlternativeText>{t("registration.fromSocial")}</StyledAlternativeText>
      <LoginGoogleForm authData={authData} setAuthData={setAuthData} t={t} />
      <StyledAuthText className="register-now-text">
        <Trans components={{ CustomLink: <CustomLink /> }} t={t} i18nKey="registration.loginLink" />
      </StyledAuthText>
    </>
  );
};

export default RegistrationFormStep;
