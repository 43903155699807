import { useEffect } from "react";
import { Trans } from "react-i18next";

import { maxLengthSymbols } from "../../../utils/consts";
import { StyledButton } from "../../styles/styledButton";
import { StyledParagraph } from "../../styles/styledTypography";
import CustomCopyToClipboard from "../copyToClipboard/CustomCopyToClipboard";
import InputGroup from "../inputGroup/InputGroup";
import LoadButton from "../spinner/ButtonSpinner";
import { StyledFirstLoginDataWrapper, StyledTwoFaForm } from "./styledTwoFa";

const TwoFa = ({
  handleSubmit,
  t,
  tempSecret,
  tempQRCode,
  handleChange,
  value,
  setValue,
  loading,
  skipAutoSubmit,
  isSubmitDisabled = false,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("twofa").focus();
  }, []);

  useEffect(() => {
    if (value.length === maxLengthSymbols.TWO_FA_CODE && !skipAutoSubmit) {
      handleSubmit();
    }
  }, [value]);

  const handleButtonClick = () => {
    navigator.clipboard.readText().then((text) => {
      const digitMatches = text.match(/\d/g);
      if (digitMatches) {
        const firstSixDigits = digitMatches.slice(0, 6).join("");
        setValue(firstSixDigits);
      } else {
        setValue("");
      }
    });
  };

  return (
    <>
      {tempSecret && tempQRCode && (
        <StyledFirstLoginDataWrapper>
          <StyledParagraph mb={15}>
            <Trans
              t={t}
              i18nKey="authCode.installGoogleAuthenticator"
              components={{
                appStoreLink: (
                  <a
                    href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
                    className="default-link"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
                googlePlayLink: (
                  <a
                    href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
                    className="default-link"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
                br: <br />,
              }}
            />
          </StyledParagraph>
          <StyledParagraph mt={40} mb={24}>
            {t("authCode.confirmLoginWith2Fa")}
          </StyledParagraph>
          <StyledParagraph className="copy-wrapper" as="div" mb={15}>
            <CustomCopyToClipboard
              value={tempSecret}
              border={false}
              display="inline-block"
              textClassName="copy-text"
            />
          </StyledParagraph>
          <img src={tempQRCode} alt="secret" />
        </StyledFirstLoginDataWrapper>
      )}
      <StyledTwoFaForm onSubmit={handleSubmit}>
        <InputGroup
          id="twofa"
          type="text"
          label={t("twoFa.description")}
          name="twofa"
          className="twofa-input"
          maxLength={maxLengthSymbols.TWO_FA_CODE}
          required
          autocomplete="off"
          handleChange={handleChange}
          value={value}
          buttonInside={
            navigator.userAgent.indexOf("Firefox") === -1 && (
              <StyledButton
                border
                type="button"
                className="insert-button"
                onClick={handleButtonClick}
              >
                <span>{t("twoFa.insert")}</span>
              </StyledButton>
            )
          }
        />
        {!loading ? (
          <StyledButton
            mt="9"
            width="100%"
            color="main"
            type="submit"
            disabled={value.length !== maxLengthSymbols.TWO_FA_CODE || isSubmitDisabled}
          >
            {t("twoFa.submit")}
          </StyledButton>
        ) : (
          <LoadButton mt="9" width="100%" weight="600" color="main" text={t("twoFa.submit")} />
        )}
      </StyledTwoFaForm>
    </>
  );
};

export default TwoFa;
