import jwt_decode from "jwt-decode";

import { unsetAuthData } from "../store/auth/authSlice";
import { setUserInfo } from "../store/user/userSlice";
import { examinationPermissionEndpointsUser } from "./customFunc/examinationPermissionEndpointsUser";
import { getLocalizedLink } from "./customFunc/getLocalizedLink";
import eventBus from "./eventBus";

const authentication = (navigate, authenticated, dispatch) => {
  if (authenticated) {
    const { userId } = jwt_decode(localStorage.getItem("token"));
    eventBus.dispatch("login-user", { id: userId });

    dispatch(setUserInfo());

    if (examinationPermissionEndpointsUser()) {
      navigate(getLocalizedLink("/crm/exchanger"));
      dispatch(unsetAuthData());
      return;
    }

    navigate(getLocalizedLink("/"));
    dispatch(unsetAuthData());
  }
};

export { authentication };
