import { createSlice } from "@reduxjs/toolkit";
import { t } from "i18next";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../utils/errorsParser";
import { runSystemCommand } from "./asyncSystemCommandsSlice";

const initialState = {
  loading: true,
};

const systemCommandsSlice = createSlice({
  name: "systemCommandsSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(runSystemCommand.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(runSystemCommand.fulfilled, (state) => {
      closableNotification(t("alerts.commandSuccessful"), "success");
      state.loading = false;
    });
    builder.addCase(runSystemCommand.rejected, (state, action) => {
      errorsParser(action.payload);
      state.loading = false;
    });
  },
});

export default systemCommandsSlice.reducer;
