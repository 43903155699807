import { GoogleOAuthProvider } from "@react-oauth/google";

import googleAuthenticationConfig from "../../utils/googleAuthenticationConfig";
import LoginGoogleButton from "./LoginGoogleButton";
import { StyledAuthWithSocialWrapper } from "./styledLogin";

const LoginGoogleForm = ({ authData, setAuthData, t }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const siteUrl = window.location.origin;

  const clientId =
    baseURL && baseURL !== siteUrl
      ? process.env.REACT_APP_EXCHANGER_GOOGLE_CLIENT_ID
      : process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const handleSubmit = (credentialResponse) => {
    if (!credentialResponse) {
      return;
    }

    setAuthData({
      ...authData,
      headers: googleAuthenticationConfig(credentialResponse),
    });
  };

  return (
    <StyledAuthWithSocialWrapper>
      <GoogleOAuthProvider clientId={clientId}>
        <LoginGoogleButton handleSubmit={handleSubmit} t={t} />
      </GoogleOAuthProvider>
    </StyledAuthWithSocialWrapper>
  );
};

export default LoginGoogleForm;
