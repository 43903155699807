import { createSlice } from "@reduxjs/toolkit";

import { errorsParser } from "../../utils/errorsParser";
import {
  createPaymentInvoiceByOrder,
  fetchOrderDetails,
  updateOrderStatus,
} from "./asyncOrderDetailsSlice";

const initialState = {
  order: {},
  error: null,
  loading: true,
  createPaymentInvoiceState: {
    loading: false,
    errors: null,
  },
};

const orderDetailsSlice = createSlice({
  name: "orderDetailsSlice",
  initialState,
  reducers: {
    clearOrderDetailsStore: (state) => {
      state.order = {};
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrderDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOrderDetails.fulfilled, (state, action) => {
      state.order = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchOrderDetails.rejected, (state, action) => {
      errorsParser(action.payload, true);
      state.loading = false;
    });
    builder.addCase(createPaymentInvoiceByOrder.pending, (state) => {
      state.createPaymentInvoiceState.loading = true;
    });
    builder.addCase(createPaymentInvoiceByOrder.fulfilled, (state, action) => {
      state.order = {
        ...state.order,
        status: action.payload.data.status,
        invoices: action.payload.data.invoices,
        updatedAt: action.payload.data.updatedAt,
      };
      state.createPaymentInvoiceState.loading = false;
    });
    builder.addCase(createPaymentInvoiceByOrder.rejected, (state, action) => {
      errorsParser(action.payload);
      state.createPaymentInvoiceState.loading = false;
    });

    builder.addCase(updateOrderStatus.pending, (state) => {
      state.createPaymentInvoiceState.loading = true;
    });
    builder.addCase(updateOrderStatus.fulfilled, (state, action) => {
      state.order = {
        ...state.order,
        status: action.payload.data.status,
      };
      state.createPaymentInvoiceState.loading = false;
    });
    builder.addCase(updateOrderStatus.rejected, (state, action) => {
      errorsParser(action.payload);
      state.createPaymentInvoiceState.loading = false;
    });
  },
});

export const { clearOrderDetailsStore } = orderDetailsSlice.actions;

export default orderDetailsSlice.reducer;
