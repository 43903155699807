import { useTranslation } from "react-i18next";

import FiltersContainer from "../../../../elements/filters/FiltersContainer";
import FilterSelect from "../../../../elements/filters/FilterSelect";
import { StyledFilterGroupBody } from "../../../../styles/styledFilter";

const WithdrawalRequisitionsFilter = ({
  filterData,
  setFilterData,
  paginationInfo,
  setPaginationInfo,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "utils.filter" });

  const handleClearFilter = () => {
    const clearFilterData = {
      page: 1,
      status: "",
    };
    setFilterData(clearFilterData);
  };

  return (
    <FiltersContainer
      paginationInfo={paginationInfo}
      setPaginationInfo={setPaginationInfo}
      filterData={filterData}
      setFilterData={setFilterData}
      handleClearFilter={handleClearFilter}
    >
      <StyledFilterGroupBody hide singleView>
        <FilterSelect
          showSearch
          label={t("labels.status")}
          name="status"
          //loading={currency?.loading}
          options={["NEW", "FINISHED", "CANCELED"]}
        />
      </StyledFilterGroupBody>
    </FiltersContainer>
  );
};

export default WithdrawalRequisitionsFilter;
