import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { useTranslation } from "react-i18next";

import { closableNotification } from "../notification/ClosableNotification";
import {
  StyledCopyIconAndTextBlock,
  StyledCustomCopyToClipboard,
} from "./StyledCustomCopyToClipboard";

const CustomCopyToClipboard = ({
  value = "",
  maxWidth,
  ellipses = true,
  display,
  border = true,
  cursor = "pointer",
  textClassName,
  hideValue,
  mt,
  mb,
  mr,
  ml,
}) => {
  const { t: tAlerts } = useTranslation("translation", { keyPrefix: "alerts" });

  return (
    <StyledCustomCopyToClipboard
      maxWidth={maxWidth}
      ellipses={ellipses}
      display={display}
      border={border}
      cursor={cursor}
      mt={mt}
      mb={mb}
      mr={mr}
      ml={ml}
    >
      <CopyToClipboard text={value} onCopy={() => closableNotification(tAlerts("copied"), "info")}>
        <StyledCopyIconAndTextBlock border={border}>
          <span className={textClassName ? `ellipses ${textClassName}` : "ellipses"}>
            {!hideValue && value}
            {!border && <span className="icon-copy" />}
          </span>
          {border && <span className="icon-copy" />}
        </StyledCopyIconAndTextBlock>
      </CopyToClipboard>
    </StyledCustomCopyToClipboard>
  );
};

export default CustomCopyToClipboard;
