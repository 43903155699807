import { errorsParser } from "../../utils/errorsParser";
import { parseWidgetSettings } from "../../utils/settings";
import { getWidgetSettings } from "./asyncWidget";

const getWidgetSettingsReducer = (builder) => {
  builder.addCase(getWidgetSettings.fulfilled, (state, action) => {
    state.token = action.payload.data.token;
    state.widgetSettings = parseWidgetSettings(action.payload.data.widgetSettings);
    state.loading = false;
  });
  builder.addCase(getWidgetSettings.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

export { getWidgetSettingsReducer };
