import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const getWithdrawalRequisitions = createAsyncThunk(
  "withdrawalRequisition/getWithdrawalRequisitions",
  async ({ filterUrl }, thunkAPI) => {
    try {
      const response = await axiosInstance.get(
        `/api/exchanger-withdrawal-requisitions${filterUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeWithdrawalRequisitionStatus = createAsyncThunk(
  "withdrawalRequisition/changeWithdrawalRequisitionStatus",
  async ({ id, callback, ...params }, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(
        `/api/exchanger-withdrawal-requisitions/${id}`,
        { ...params },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        callback,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export { changeWithdrawalRequisitionStatus, getWithdrawalRequisitions };
