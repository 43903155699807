import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { clearStore } from "../../store/auth/authSlice";
import TwoFa from "../elements/twoFa/TwoFa";
import { StyledMainTitle } from "../styles/styledTitle";

const LoginTwoFaForm = ({ authData, setAuthData, t, loading }) => {
  const { firstLoginData } = useSelector((state) => state.auth);
  const [twoFa, setTwoFa] = useState("");
  const tempQRCode = firstLoginData?.tempQRCode;
  const tempSecret = firstLoginData?.tempSecret;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearStore());
    };
  }, []);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    setAuthData({
      ...authData,
      body: {
        twofa: twoFa,
      },
    });
  };

  const handleChange = (event) => {
    setTwoFa(event.target.value.replace(/\D/g, ""));
  };

  return (
    <div>
      <StyledMainTitle mb="16" size="22" position="center">
        {t("twoFa.title")}
      </StyledMainTitle>
      <TwoFa
        handleSubmit={handleSubmit}
        loading={loading}
        t={t}
        tempQRCode={tempQRCode}
        tempSecret={tempSecret}
        handleChange={handleChange}
        value={twoFa}
        setValue={setTwoFa}
      />
    </div>
  );
};

export default LoginTwoFaForm;
