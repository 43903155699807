import { errorsParser } from "../../utils/errorsParser";
import { fetchReferralToken, fetchReferralUsers } from "./asyncReferralSystem";

const fetchReferralTokenReducer = (builder) => {
  builder.addCase(fetchReferralToken.fulfilled, (state, action) => {
    state.referralToken = action.payload.data;
    state.loading = false;
  });
  builder.addCase(fetchReferralToken.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const fetchReferralUsersReducer = (builder) => {
  builder.addCase(fetchReferralUsers.fulfilled, (state, action) => {
    state.referralUsers.list = action.payload.data;
    state.paginationInfo = {
      ...state.paginationInfo,
      ...action.payload.paginationInfo,
    };
    state.referralUsers.loading = false;
  });
  builder.addCase(fetchReferralUsers.rejected, (state, action) => {
    errorsParser(action.payload);
    state.referralUsers.loading = false;
  });
};

export { fetchReferralTokenReducer, fetchReferralUsersReducer };
