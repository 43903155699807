import styled from "styled-components";

const type = {
  type: "",
};

const changeType = (type, border, theme) => {
  switch (type) {
    case "error":
      return `
        ${border && `border: 1px solid ${theme.danger.first}`};
        background-color: ${theme.danger.second};
        &:before {
          content: '\\e92a';
          background-color: ${theme.danger.first};
          color: ${theme.neutral.white};
        }
      `;
    case "success":
      return `
        ${border && `border: 1px solid ${theme.success.first}`};
        background-color: ${theme.success.second};
        &:before {
          content: '\\e92b';
          background-color: ${theme.success.first};
          color: ${theme.neutral.white};
        }
      `;
    case "warning":
      return `
        ${border && `border: 1px solid ${theme.warning.first}`};
        background-color: ${theme.warning.second};
        &:before {
          content: '\\e92a';
          background-color: ${theme.warning.first};
          color: ${theme.neutral.white};
        }
      `;
    case "info":
      return `
        ${border && `border: 1px solid ${theme.info.first}`};
        background-color: ${theme.info.second};
        &:before {
          content: '\\e92a';
          background-color: ${theme.info.first};
          color: ${theme.neutral.white};
        }
      `;
    default:
      return `
        ${border && `border: 1px solid ${theme.info.first}`};
        background-color: ${theme.info.second};
        &:before {
          content: '\\e92a';
          background-color: ${theme.info.first};
          color: ${theme.neutral.white};
        }
      `;
  }
};

export const StyledAlertWrapper = styled("div", type)`
  ${({ width }) => width && `width: ${width}`};
  ${({ center }) => center && "max-width: 1110px"};
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  padding: 16px 16px 16px 68px;
  color: ${({ theme }) => theme.neutral.second};
  border-radius: 12px;
  position: relative;

  &:before {
    width: 28px;
    height: 28px;
    font-size: 20px;
    font-family: "default-icons", serif;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    border-radius: 50%;
  }

  ${({ type, border, theme }) => changeType(type, border, theme)}
  ${({ border }) => border === false && "border-color: transparent"};
`;

export const StyledAlertContainer = styled.div`
  color: ${({ theme }) => theme.neutral.first};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
`;
