import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const getWidgetSettings = createAsyncThunk(
  "referralWidget/getWidgetSettings",
  async ({ metaUrl }, thunkAPI) => {
    try {
      const response = await axiosInstance.get(
        `/api/exchanger-widget-settings/by-meta-url/${metaUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export { getWidgetSettings };
