import { createSlice } from "@reduxjs/toolkit";

import { fetchReferralTokenReducer, fetchReferralUsersReducer } from "./referralSystemReducer";

const initialState = {
  referralToken: "",
  errors: null,
  loading: true,
  referralUsers: {
    loading: true,
    list: [],
  },
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
  },
};

const referralSystemSlice = createSlice({
  name: "referralSystem",
  initialState,
  reducers: {
    setReferralPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearStateConnections: (state) => {
      state.referralToken = [];
      state.loading = true;
      state.errors = null;
    },
    clearStateReferralUsers: (state) => {
      state.referralUsers.list = [];
      state.referralUsers.loading = true;
    },
  },
  extraReducers: (builder) => {
    fetchReferralTokenReducer(builder);
    fetchReferralUsersReducer(builder);
  },
});

export const { clearStateConnections, clearStateReferralUsers, setReferralPaginationInfo } =
  referralSystemSlice.actions;

export default referralSystemSlice.reducer;
