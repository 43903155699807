import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchReferralWidgets = createAsyncThunk(
  "referralWidget/fetchReferralWidgets",
  async ({ filterUrl }, thunkAPI) => {
    const url = `/api/widgets${filterUrl}`;

    try {
      const response = await axiosInstance.get(url, userAuthenticationConfig());

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const fetchReferralWidgetById = createAsyncThunk(
  "referralWidget/fetchReferralWidgetById",
  async ({ id }, thunkAPI) => {
    const url = `/api/widgets/${id}`;

    try {
      const response = await axiosInstance.get(url, userAuthenticationConfig());
      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeReferralWidgetStatus = createAsyncThunk(
  "referralWidget/changeReferralWidgetStatus",
  async ({ id, status }, thunkAPI) => {
    const url = `/api/widgets/${id}`;
    try {
      const response = await axiosInstance.patch(url, { status }, userAuthenticationConfig());

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const createReferralWidget = createAsyncThunk(
  "referralWidget/createReferralWidget",
  async ({ name, url, callback }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/widgets",
        { name, url },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        callback,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchWidgetPairs = createAsyncThunk(
  "referralWidget/fetchWidgetPairs",
  async ({ filterUrl }, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/exchanger-widget-pairs${filterUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeWidgetPair = createAsyncThunk(
  "referralWidget/changeWidgetPair",
  async ({ id, percent, isActive, notificationsCallback }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/exchanger-widget-pairs/${id}`,
        { percent, isActive },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        notificationsCallback,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeWidgetSettings = createAsyncThunk(
  "referralWidget/changeWidgetSettings",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        `/api/exchanger-widget-settings-change/${id}`,
        { ...data },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchWidgetReports = createAsyncThunk(
  "referralWidget/fetchWidgetReports",
  async ({ id, filterUrl }, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/exchanger-widget-reports/${id}${filterUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const updateWidgetReports = createAsyncThunk(
  "referralWidget/updateWidgetReports",
  async ({ data, token }, thunkAPI) => {
    try {
      await axiosInstance.post(
        `/api/exchanger-widget-reports/update?token=${token}`,
        data,
        userAuthenticationConfig()
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeReferralWidgetCustomFee = createAsyncThunk(
  "referralWidget/changeReferralWidgetCustomFee",
  async ({ id, isEnabledCustomFee }, thunkAPI) => {
    const url = `/api/widgets/${id}`;
    try {
      const response = await axiosInstance.patch(
        url,
        { isEnabledCustomFee },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export {
  changeReferralWidgetCustomFee,
  changeReferralWidgetStatus,
  changeWidgetPair,
  changeWidgetSettings,
  createReferralWidget,
  fetchReferralWidgetById,
  fetchReferralWidgets,
  fetchWidgetPairs,
  fetchWidgetReports,
  updateWidgetReports,
};
