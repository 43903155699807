import styled from "styled-components";

import apple from "../../assets/images/icon-apple.svg";
import google from "../../assets/images/icon-google.svg";
import { StyledButton } from "../styles/styledButton";

export const StyledLoginWrapper = styled.div`
  padding-top: 50px;
  max-width: 420px;
  margin: 0 auto;
  overflow-x: hidden;
  .login-action {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 0 15px;
    text-align: center;
  }

  .forgot-password {
    padding-top: 8px;
    padding-bottom: 24px;
    text-align: right;
  }
  .register-now-text {
    padding-top: 24px;
  }
`;

export const StyledLoginForm = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const StyledAuthWithSocialWrapper = styled.div`
  max-width: 420px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 16px;

  .social-login {
    padding-left: 42px;
    position: relative;

    &::before {
      content: "";
      height: 20px;
      width: 20px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &_with-google::before {
      background-image: url(${google});
    }

    &_with-apple::before {
      background-image: url(${apple});
    }
  }

  ${StyledButton} {
    width: 100%;
    padding: 9px 10px;
  }
`;

export const StyledAuthText = styled.div`
  ${({ padding }) => (padding ? `padding: ${padding}` : "padding: 15px 0;")};
  text-align: center;
  opacity: 0.75;
`;

export const StyledAlternativeText = styled.div`
  padding: 24px 0;
  text-align: center;
  color: #6e6e6e;
  font-weight: 500;
  position: relative;
  display: flex;
  text-wrap: nowrap;

  &::before,
  &::after {
    content: "";
    width: 40%;
    height: 1px;
    background-color: #d3d3d3;
    margin-top: 9px;
  }

  &::before {
    margin-right: 16px;
  }

  &::after {
    margin-left: 16px;
  }
`;
