export const permissionAdminEndpoints = {
  PUT_EXCHANGER_CHANGE_AVATAR: { name: "put_exchanger_change_avatar", hasFullAccess: true },
  PUT_EXCHANGER_REMOVE_AVATAR: { name: "put_exchanger_remove_avatar", hasFullAccess: true },
  API_EXCHANGER_BALANCES_GET_COLLECTION: {
    name: "api_exchanger_balances_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_BALANCES_GET_ITEM: { name: "api_exchanger_balances_get_item", hasFullAccess: true },
  API_EXCHANGER_BALANCES: { name: "get_exchanger_balances", hasFullAccess: true },
  API_EXCHANGER_INVOICES_GET_COLLECTION: {
    name: "api_exchanger_invoices_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_INVOICES_GET_ITEM: { name: "api_exchanger_invoices_get_item", hasFullAccess: true },
  API_EXCHANGER_ORDERS_GET_COLLECTION: {
    name: "api_exchanger_orders_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_ORDERS_GET_ITEM: { name: "api_exchanger_orders_get_item", hasFullAccess: true },
  API_EXCHANGER_PAIR_UNITS_GET_COLLECTION: {
    name: "api_exchanger_pair_units_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_PAIR_UNITS_GET_ITEM: {
    name: "api_exchanger_pair_units_get_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_PAIR_UNITS_PUT_ITEM: {
    name: "api_exchanger_pair_units_put_item",
    hasFullAccess: true,
  },
  FILL_EXCHANGER_PAIR_UNITS: { name: "fill_exchanger_pair_units", hasFullAccess: true },
  API_EXCHANGER_PAIRS_GET_COLLECTION: {
    name: "api_exchanger_pairs_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_PAIRS_GET_ITEM: { name: "api_exchanger_pairs_get_item", hasFullAccess: true },
  API_EXCHANGER_PAIRS_CHANGE_PERCENT_ITEM: {
    name: "api_exchanger_pairs_change_percent_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_USER_INFOS_GET_COLLECTION: {
    name: "api_exchanger_user_infos_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_USER_INFOS_GET_ITEM: {
    name: "api_exchanger_user_infos_get_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_USERS_GET_COLLECTION: {
    name: "api_exchanger_users_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_USERS_GET_ITEM: { name: "api_exchanger_users_get_item", hasFullAccess: true },
  API_EXCHANGER_USERS_PATCH_ITEM: { name: "api_exchanger_users_patch_item", hasFullAccess: true },
  API_EXCHANGER_USERS_WIDGET_STATE_ITEM: {
    name: "api_exchanger_users_change_widget_state_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_WIDGET_PAIRS_GET_COLLECTION: {
    name: "api_exchanger_widget_pairs_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_WIDGET_PAIRS_GET_ITEM: {
    name: "api_exchanger_widget_pairs_get_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_WIDGETS_PATCH_ITEM: {
    name: "api_exchanger_widgets_patch_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_WIDGET_PAIRS_PUT_ITEM: {
    name: "api_exchanger_widget_pairs_put_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_WIDGETS_GET_COLLECTION: {
    name: "api_exchanger_widgets_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_WIDGETS_GET_ITEM: { name: "api_exchanger_widgets_get_item", hasFullAccess: true },
  API_EXCHANGER_WIDGET_SETTINGS_GET_COLLECTION: {
    name: "api_exchanger_widget_settings_get_collection",
    hasFullAccess: true,
  },
  POST_EXCHANGER_WIDGET_SETTINGS: { name: "post_exchanger_widget_settings", hasFullAccess: true },
  API_EXCHANGER_WIDGET_SETTINGS_GET_ITEM: {
    name: "api_exchanger_widget_settings_get_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_REFERRAL_USER_INFO_PUT_ITEM: {
    name: "api_exchanger_referral_user_infos_put_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_ENDPOINTS_GET_COLLECTION: {
    name: "api_exchanger_endpoints_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_ENDPOINTS_GET_ITEM: {
    name: "api_exchanger_endpoints_get_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_ENDPOINT_PERMISSIONS_GET_COLLECTION: {
    name: "api_exchanger_endpoint_permissions_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_ENDPOINT_PERMISSIONS_POST_COLLECTION: {
    name: "api_exchanger_endpoint_permissions_post_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_ENDPOINT_PERMISSIONS_GET_ITEM: {
    name: "api_exchanger_endpoint_permissions_get_item",
    hasFullAccess: true,
  },
  API_EXCHANGER_ENDPOINT_PERMISSIONS_DELETE_ITEM: {
    name: "api_exchanger_endpoint_permissions_delete_item",
    hasFullAccess: true,
  },
  GET_EXCHANGER_ENDPOINT_PERMISSION_BY_USER_ID: {
    name: "get_exchanger_endpoint_permission_by_user_id",
    hasFullAccess: true,
  },
  PUT_EXCHANGER_ENDPOINT_PERMISSION_EDIT_ACCESS: {
    name: "put_exchanger_endpoint_permission_edit_access",
    hasFullAccess: true,
  },
  POST_EXCHANGER_ENDPOINT_PERMISSION_BY_GROUP: {
    name: "post_exchanger_endpoint_permission_by_group",
    hasFullAccess: true,
  },
  GET_EXCHANGER_ENDPOINTS_LIST: { name: "get_exchanger_endpoints_list", hasFullAccess: true },
  API_EXCHANGER_GROUP_PERMISSIONS_GET_COLLECTION: {
    name: "api_exchanger_group_permissions_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_GROUP_PERMISSIONS_GET_ITEM: {
    name: "api_exchanger_group_permissions_get_item",
    hasFullAccess: true,
  },
  EXCHANGER_UPDATE_DATABASE_SCHEMA: {
    name: "exhanger_update_database_schema",
    hasFullAccess: true,
  },
  API_EXCHANGER_PAIR_UNITS_CHANGE_RATE_UPDATE_SERVICE_ITEM: {
    name: "api_exchanger_pair_units_change_rate_update_service_item",
    hasFullAccess: true,
  },
  PUT_EXCHANGER_USERS_CHANGE_PASSWORD: {
    name: "put_exchanger_users_change_password",
    hasFullAccess: true,
  },
  GET_EXCHANGER_RATES: { name: "get_exchanger_rates", hasFullAccess: true },
  GET_EXCHANGER_AUTHENTICATOR_SECRET: {
    name: "get_exchanger_authenticator_secret",
    hasFullAccess: true,
  },
  GET_EXCHANGER_AUTHENTICATOR_SECRET_IS_ACTIVE: {
    name: "get_exchanger_authenticator_secret_is_active",
    hasFullAccess: true,
  },
  GET_EXCHANGER_RATE_SERVICES: {
    name: "get_exchanger_rate_services",
    hasFullAccess: true,
  },
  CHANGE_EXCHANGER_RATES_MARKUP: {
    name: "change_exchanger_rates_markup",
    hasFullAccess: true,
  },
  RESET_EXCHANGER_USER_VERIFICATION: {
    name: "reset_exchanger_user_verification",
    hasFullAccess: true,
  },
  GET_EXCHANGER_USER_VERIFIED_VERIFICATION_SCHEMAS: {
    name: "get_exchanger_user_verified_verification_schemas",
    hasFullAccess: true,
  },
  GET_EXCHANGER_USER_VERIFICATIONS: {
    name: "get_exchanger_user_verifications",
    hasFullAccess: true,
  },
  POST_EXCHANGER_USER_VERIFICATION: {
    name: "post_exchanger_user_verification",
    hasFullAccess: true,
  },
  POST_CALCULATOR_SETTINGS: { name: "post_calculator_settings", hasFullAccess: true },
  DELETE_CALCULATOR_SETTINGS: { name: "delete_calculator_settings", hasFullAccess: true },
  GET_CALCULATOR_SETTINGS: { name: "get_calculator_settings", hasFullAccess: true },
  GET_EXCHANGER_ORDERS_REPORT: { name: "get_exchanger_orders_report", hasFullAccess: true },
  API_EXCHANGER_WITHDRAWAL_REQUISITION_GET_COLLECTION: {
    name: "api_exchanger_withdrawal_requisitions_get_collection",
    hasFullAccess: true,
  },
  API_EXCHANGER_WIDGET_REPORTS_GET_COLLECTION: {
    name: "api_exchanger_widget_reports_get_collection",
    hasFullAccess: true,
  },
  PUT_EXCHANGER_USER_INFO: { name: "put_exchanger_user_info", hasFullAccess: true },
};
