import { useState } from "react";

import {
  StyledError,
  StyledFixedError,
  StyledInput,
  StyledInputGroup,
  StyledInputWrapper,
  StyledLabel,
  StyledPasswordEye,
} from "./styledInputGroup";

const InputGroup = ({
  id,
  label,
  type,
  className,
  handleChange,
  name,
  maxLength,
  autocomplete,
  readonly,
  error,
  fixedError = null,
  buttonInside,
  customType,
  customChangeTypeCallback,
  ...otherProps
}) => {
  const [typePassword, setTypePassword] = useState("password");

  const changeType = () => {
    if (customChangeTypeCallback) {
      customChangeTypeCallback();
    } else setTypePassword(typePassword === "password" ? "text" : "password");
  };

  return (
    <StyledInputGroup
      className={`input-group ${className ?? ""}`}
      type={type}
      fixedError={fixedError}
    >
      {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
      <StyledInputWrapper error={error}>
        <StyledInput
          type={customType || (type === "password" ? typePassword : type)}
          id={id}
          name={name}
          autoComplete={autocomplete}
          maxLength={maxLength}
          onChange={handleChange}
          showButton={type === "password"}
          {...otherProps}
        />
        {type === "password" && (
          <StyledPasswordEye
            className={
              (customType ? customType : typePassword) === "password"
                ? "icon-eye-closed"
                : "icon-eye-open"
            }
            type="button"
            onClick={changeType}
          />
        )}
        {buttonInside && buttonInside}
      </StyledInputWrapper>
      {error && typeof error !== "boolean" && !fixedError && <StyledError>{error}</StyledError>}
      {fixedError && (
        <StyledFixedError>{error && typeof error !== "boolean" ? error : ""}</StyledFixedError>
      )}
    </StyledInputGroup>
  );
};

export default InputGroup;
