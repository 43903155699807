import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { fetchWithdrawalRequisitions } from "../../../../../store/clients/asyncClientsSlice";
import {
  clearStateWithdrawalRequisitions,
  setPaginationInfo,
} from "../../../../../store/clients/clientsSlice";
import { getItemsPerPage } from "../../../../../utils/customFunc/getItemsPerPage";
import { checkFilterItem, fetchFilterData } from "../../../../../utils/fetchFilterData";
import CustomPagination from "../../../../elements/pagination/CustomPagination";
import { StyledWithdrawalRequisitionsContainer } from "./styledWithdrawalRequisitions";
import WithdrawalRequisitionsFilter from "./WithdrawalRequisitionsFilter";
import WithdrawalRequisitionsList from "./WithdrawalRequisitionsList";

const WithdrawalRequisitions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { withdrawalRequisitions, paginationInfo } = useSelector((store) => store.clients);
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const [filterData, setFilterData] = useState({
    page: checkFilterItem(searchParams, "page", 1, true),
    status: checkFilterItem(searchParams, "status", null),
  });

  const { loading, list } = withdrawalRequisitions || {};

  const getWithdrawalRequisitions = () => {
    const filterUrl = fetchFilterData({ ...filterData, ...getItemsPerPage(paginationInfo) });
    navigate(filterUrl + "&currentTab=withdrawalRequisitions");

    dispatch(fetchWithdrawalRequisitions({ id, filterUrl }));
  };

  useEffect(() => {
    getWithdrawalRequisitions();

    return () => dispatch(clearStateWithdrawalRequisitions());
  }, [filterData, paginationInfo.itemsPerPage]);

  return (
    <StyledWithdrawalRequisitionsContainer>
      <WithdrawalRequisitionsFilter
        filterData={filterData}
        setFilterData={setFilterData}
        paginationInfo={paginationInfo}
        setPaginationInfo={setPaginationInfo}
      />
      <WithdrawalRequisitionsList loading={loading} list={list} />
      <CustomPagination
        filterData={filterData}
        setFilterData={setFilterData}
        paginationInfo={paginationInfo}
      />
    </StyledWithdrawalRequisitionsContainer>
  );
};

export default WithdrawalRequisitions;
