import React, { lazy, Suspense } from "react";

import withPermissionCheckPage from "../../utils/customHOC/withPermissionCheckPage";
import { suspenseFallbackFactory } from "./suspenseFallbackFactory";

const SuspenseWrapper = ({
  pageComponentPath,
  permission,
  cookiesLang,
  checkFullAccess = false,
  dispatch,
}) => {
  //todo commented until find more best decision
  //   if (!isSuspense) {
  //     const ComponentToRender = withPermissionCheckPage(
  //       require(`../../pages${pageComponentPath}`).default,
  //       permission,
  //       cookiesLang
  //     );
  //
  //     return <ComponentToRender />;
  //   }

  const LazyComponent = withPermissionCheckPage(
    lazy(() => import(`../../pages${pageComponentPath}`)),
    permission,
    cookiesLang,
    checkFullAccess,
    dispatch
  );

  return (
    <Suspense fallback={suspenseFallbackFactory(pageComponentPath)}>
      <LazyComponent />
    </Suspense>
  );
};

export default React.memo(SuspenseWrapper);
