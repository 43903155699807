import { createSlice } from "@reduxjs/toolkit";

import i18n from "../../i18n";
import { defaultLanguage } from "../../utils/consts";

const languageSlice = createSlice({
  name: "language",
  initialState: i18n.language || defaultLanguage,
  reducers: {
    setLanguage: (state, action) => {
      i18n.changeLanguage(action.payload);
      return action.payload;
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
