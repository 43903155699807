import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProfitsInfo } from "../../../store/userBalance/asyncUserBalance";
import { defaultRoute, exchangerRoles } from "../../../utils/consts";
import { checkPermission, checkPermissionByName } from "../../../utils/customFunc/checkPermission";
import { groupPermissionEndpoints } from "../../../utils/groupEndpoints/groupPermissionEndpoints";
import CustomLink from "../../elements/customLink/CustomLink";
import LanguageSelect from "../../elements/language/LanguageSelect";
import Logo from "../../elements/logo/Logo";
import Navigation from "../../elements/navigation/Navigation";
import BalanceButton from "../../userBalance/BalanceButton";
import {
  StyledHeaderContainer,
  StyledHeaderWrapper,
  StyledMobileSideBarIcon,
} from "./styledHeader";

const Header = ({
  authenticated,
  isAccountPathname,
  collapsed,
  toggled,
  handleCloseSideBar,
  toggleMobile,
}) => {
  const dispatch = useDispatch();
  const { profits, profitLoading } = useSelector((state) => state.userBalance);
  const { roles } = useSelector((store) => store.user.user) || {};

  const isUser = roles?.some((role) => role === exchangerRoles.USER);

  const haveAccess = useMemo(
    () => checkPermissionByName([groupPermissionEndpoints.API_EXCHANGER_BALANCES]),
    []
  );

  const getProfitByType = (searchedType) =>
    profits?.find(({ type }) => type === searchedType)?.amount;

  const userAccountLink = () => {
    // if (authenticated && user?.roles[arrayOperation.FIRST] === roles.ADMIN) {
    //   return `${typeAccountRoutes.CRM}/clients`;
    // }
    //
    // if (authenticated && user?.roles[arrayOperation.FIRST] === roles.SYSTEM_ADMIN) {
    //   return `${typeAccountRoutes.CRM}/servers`;
    // }

    if (authenticated) {
      return defaultRoute;
    }

    return "/login";
  };

  useEffect(() => {
    if (isUser && haveAccess) {
      dispatch(getProfitsInfo());
    }
  }, [isUser, dispatch, haveAccess]);

  return (
    <StyledHeaderWrapper>
      <StyledHeaderContainer>
        <div className="header-left">
          <Logo isAccountPathname={isAccountPathname} />
          {isAccountPathname && (
            <button className="header-left__collapse" onClick={handleCloseSideBar}>
              <span className={collapsed ? "icon-chevron-right" : "icon-chevron-left"} />
            </button>
          )}
        </div>
        {!isAccountPathname && (
          <Navigation authenticated={authenticated} userAccountLink={userAccountLink} />
        )}
        <div className="header-right">
          {isUser && haveAccess && (
            <BalanceButton loading={profitLoading} totalAmount={getProfitByType("actual")} />
          )}

          <LanguageSelect />

          {isAccountPathname ? (
            <StyledMobileSideBarIcon
              authenticated={authenticated}
              show={toggled}
              onClick={toggleMobile}
            >
              <div className="navbar-top" />
              <div className="navbar-middle" />
              <div className="navbar-bottom" />
            </StyledMobileSideBarIcon>
          ) : (
            <CustomLink className="header-right__account" to={userAccountLink()}>
              <span className="header-right__account-title">
                {authenticated ? "My account" : "Sign in"}
              </span>
              <span className="icon-login" />
            </CustomLink>
          )}
        </div>
      </StyledHeaderContainer>
    </StyledHeaderWrapper>
  );
};

export default Header;
