import styled from "styled-components";

export const StyledMainTitle = styled.h1`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ size }) => size && `font-size: ${size}px`};
  color: ${({ color, theme }) => color ?? theme.neutral.first};
  font-weight: 700;
  text-align: ${({ position }) => (position === "center" ? "center" : "left")};
`;

export const StyledMainDescription = styled.div`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ size }) => size && `font-size: ${size}px`};
  color: ${({ theme }) => theme.neutralPrimary};
  text-align: ${({ position }) => (position === "center" ? "center" : "left")};
`;
