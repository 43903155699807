import Select, { Option } from "rc-select";
import { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getItemsPerPage } from "../../../utils/customFunc/getItemsPerPage";
import { StyledPerPageSelect } from "../../styles/styledFilter";

const FilterItemsPerPage = ({ setPaginationInfo, custom, paginationInfo, setFilterData }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(Number(getItemsPerPage(paginationInfo).itemsPerPage));
  const items = custom || [10, 20, 30, 50, 100];

  const handleChangeValue = (value) => {
    const newPaginationInfo = {
      ...paginationInfo,
      itemsPerPage: value,
      page: 1,
    };
    setFilterData((prev) => ({ ...prev, page: 1 }));

    dispatch(setPaginationInfo(newPaginationInfo));

    return value;
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const itemsPerPage = params.get("itemsPerPage");

    // save itemsPerPage when we leave page
    return () => {
      if (!paginationInfo.itemsPerPage && itemsPerPage) {
        dispatch(
          setPaginationInfo({
            ...paginationInfo,
            itemsPerPage,
          })
        );
      }
    };
  }, []);

  return (
    <StyledPerPageSelect>
      <Select
        className="custom-select"
        name={"select"}
        value={value}
        onChange={(value) => handleChangeValue(value) | setValue(value)}
      >
        {items.map((item) => (
          <Option value={item} key={item}>
            <div className="option-select-item">{item}</div>
          </Option>
        ))}
      </Select>
    </StyledPerPageSelect>
  );
};

export default memo(FilterItemsPerPage);
