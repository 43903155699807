import Dialog from "rc-dialog";
import Dropdown from "rc-dropdown";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useMedia } from "../../../utils/customHooks/useMedia";
import { StyledButton } from "../../styles/styledButton";
import {
  StyledFilterBackdrop,
  StyledFilterBody,
  StyledFilterFooter,
  StyledFilterHeader,
  StyledFilterWrapper,
} from "../../styles/styledFilter";
import { StyledHeadingThree, StyledSpan } from "../../styles/styledTypography";
import { FilterDataProvider } from "./FilterContext";
import FilterItemsPerPage from "./FilterItemsPerPage";

const FiltersContainer = ({
  filterData,
  setFilterData,
  paginationInfo,
  setPaginationInfo,
  children,
  handleClearFilter,
  buttonAlign,
  fetchFiltersCallback,
  defaultQueryQuantity = 1,
}) => {
  // defaultQueryQuantity - мінімально необхідна кількість параметрів, при якій кнопка очистки фільтра є неактивною,
  // по дефолту буде 1 так як ?page=1 є обов"язковою для всіх сторінок де є пагінація і фільтр
  const isMobile = useMedia("(max-width: 992px)");
  const { t } = useTranslation("translation", { keyPrefix: "utils.filter" });

  const [visible, setVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const onVisibleChange = (visible) => setVisible(visible);
  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (visible && fetchFiltersCallback && !isMounted) {
      fetchFiltersCallback();
      setIsMounted(true);
    }
  }, [visible, fetchFiltersCallback, isMounted]);

  const valuesArrFromFilter = Object.values(filterData);

  const hasMoreThanOneValue = (arr) => {
    const filteredArray = arr.filter((item) => item !== "" && item != null);

    return filteredArray.length > defaultQueryQuantity;
  };

  return (
    <StyledFilterWrapper id="filter" buttonAlign={buttonAlign}>
      <FilterDataProvider filterData={filterData} setFilterData={setFilterData}>
        {isMobile ? (
          <>
            <Dialog
              visible={visible}
              wrapClassName="filter-modal-wrapper"
              onClose={handleClose}
              animation="zoom"
              maskAnimation="fade"
              title={t("applyFilters")}
              forceRender={false}
              className="filter-modal"
              zIndex={1090}
            >
              <div className="default-modal__content">
                <StyledFilterBody>
                  {children}

                  <StyledFilterFooter>
                    <StyledButton
                      border
                      onClick={handleClearFilter}
                      withIcon
                      className="footer-buton"
                      disabled={!hasMoreThanOneValue(valuesArrFromFilter)}
                    >
                      <span className="icon-eraser" />
                      {t("clearFilter")}
                    </StyledButton>

                    <StyledButton className="footer-buton" onClick={handleClose}>
                      {t("applyFilter")}
                    </StyledButton>
                  </StyledFilterFooter>
                </StyledFilterBody>
              </div>
            </Dialog>

            <StyledButton
              color="white"
              className="apply-button"
              withIcon
              onClick={() => setVisible(!visible)}
            >
              <span className="icon-filter" />
              {t("applyFilter")}
            </StyledButton>
          </>
        ) : (
          <>
            <Dropdown
              overlay={
                <>
                  <StyledFilterBody>
                    <StyledFilterHeader>
                      <StyledHeadingThree mb="2">{t("applyFilters")}</StyledHeadingThree>
                      <StyledSpan
                        fontSize="24"
                        className="icon-cancel_icon"
                        cursor="pointer"
                        onClick={handleClose}
                        role="button"
                      />
                    </StyledFilterHeader>

                    {children}

                    <StyledFilterFooter>
                      <StyledButton
                        border
                        onClick={handleClearFilter}
                        withIcon
                        className="footer-buton"
                        disabled={!hasMoreThanOneValue(valuesArrFromFilter)}
                      >
                        <span className="icon-eraser" />
                        {t("clearFilter")}
                      </StyledButton>
                    </StyledFilterFooter>
                  </StyledFilterBody>
                </>
              }
              trigger={["click"]}
              visible={visible}
              onVisibleChange={onVisibleChange}
              placement="bottomRight"
              maskClosable={false}
              getPopupContainer={() => document.getElementById("filter")}
              zIndex={6}
            >
              <StyledButton color="white" withIcon className="apply-button">
                <span className="icon-filter" />
                {t("applyFilter")}
              </StyledButton>
            </Dropdown>
            {visible && <StyledFilterBackdrop />}
          </>
        )}
      </FilterDataProvider>

      {paginationInfo && setPaginationInfo && (
        <FilterItemsPerPage
          paginationInfo={paginationInfo}
          setPaginationInfo={setPaginationInfo}
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )}
    </StyledFilterWrapper>
  );
};

export default FiltersContainer;
