import { t } from "i18next";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../utils/errorsParser";
import {
  createWithdrawalRequisition,
  fetchWithdrawalPairUnits,
  getProfitsInfo,
} from "./asyncUserBalance";

const getProfitsInfoReducer = (builder) => {
  builder.addCase(getProfitsInfo.pending, (state) => {
    state.profitLoading = true;
  });
  builder.addCase(getProfitsInfo.fulfilled, (state, action) => {
    state.profits = action.payload.data;
    state.profitLoading = false;
  });
  builder.addCase(getProfitsInfo.rejected, (state, action) => {
    errorsParser(action.payload);
    state.profitLoading = false;
  });
};

const fetchWithdrawalPairUnitsReducer = (builder) => {
  builder.addCase(fetchWithdrawalPairUnits.pending, (state) => {
    state.withdrawalPairLoading = true;
  });
  builder.addCase(fetchWithdrawalPairUnits.fulfilled, (state, action) => {
    state.withdrawalPair = action.payload.data;
    state.withdrawalPairLoading = false;
  });
  builder.addCase(fetchWithdrawalPairUnits.rejected, (state, action) => {
    errorsParser(action.payload);
    state.withdrawalPairLoading = false;
  });
};

const createWithdrawalRequisitionReducer = (builder) => {
  builder.addCase(createWithdrawalRequisition.pending, (state) => {
    state.createWithdrawalLoading = true;
  });
  builder.addCase(createWithdrawalRequisition.fulfilled, (state, action) => {
    !!action.payload.callback && action.payload.callback();
    state.createWithdrawalLoading = false;

    closableNotification(t("alerts.successfulWithdrawalRequest"), "success");
  });
  builder.addCase(createWithdrawalRequisition.rejected, (state, action) => {
    errorsParser(action.payload);
    state.createWithdrawalLoading = false;
  });
};

export {
  createWithdrawalRequisitionReducer,
  fetchWithdrawalPairUnitsReducer,
  getProfitsInfoReducer,
};
