import { useEffect } from "react";

const OnlineChat = ({ siteConfig }) => {
  useEffect(() => {
    const { onlineChat } = siteConfig;

    if (!onlineChat) {
      return;
    }

    const script = document.createElement("script");
    script.src = "https://cdn.txtapp.me/wtmchat.js";
    script.dataset.key = onlineChat;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [siteConfig]);

  return null;
};

export default OnlineChat;
