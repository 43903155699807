import Pagination from "rc-pagination";
import React from "react";
import { withTranslation } from "react-i18next";

import { getItemsPerPage } from "../../../utils/customFunc/getItemsPerPage";
import { StyledCustomPaginationWrapper } from "./styledCustomPagination";

const CustomPagination = ({ t, paginationInfo, filterData, setFilterData, ...otherProps }) => {
  const locale = {
    items_per_page: t("itemsPerPage"),
    jump_to: t("jumpTo"),
    jump_to_confirm: t("jumpToConfirm"),
    page: t("page"),
    prev_page: t("prevPage"),
    next_page: t("nextPage"),
    prev_5: t("prev5"),
    next_5: t("next5"),
    prev_3: t("prev3"),
    next_3: t("next3"),
    page_size: t("pageSize"),
  };

  const onChangePage = (page) => {
    setFilterData({ ...filterData, page: page });
  };

  if (!paginationInfo.totalPageCount) {
    return null;
  }

  return (
    <StyledCustomPaginationWrapper>
      {paginationInfo.totalPageCount && (
        <Pagination
          className="custom-pagination"
          total={paginationInfo.totalItems}
          current={filterData.page}
          onChange={onChangePage}
          pageSize={getItemsPerPage(paginationInfo).itemsPerPage}
          locale={locale}
          {...otherProps}
        />
      )}
    </StyledCustomPaginationWrapper>
  );
};

export default withTranslation("translation", { keyPrefix: "pagination" })(CustomPagination);
