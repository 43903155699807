import styled from "styled-components";

export const StyledWithdrawalRequisitionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  #filter {
    justify-content: flex-start;
  }

  .withdrawal-requisitions {
    @media screen and (min-width: 993px) {
      &__head,
      &__row {
        grid-template-columns: 50px 100px repeat(3, 1fr) 1.5fr;
      }
    }
  }
`;
