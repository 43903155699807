import styled from "styled-components";

export const StyledCustomPaginationWrapper = styled.div`
  width: 100%;
  height: 45px;
  padding: 8px 16px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.neutral.five};
  background-color: ${({ theme }) => theme.mainTemplateBackgroundColor};
  position: fixed;
  bottom: 0;
  right: 0;

  .custom-pagination {
    .rc-pagination-item {
      background-color: transparent;
      border: none;
      border-radius: 8px;
      outline: none;

      a {
        width: 28px;
        height: 28px;
        color: ${({ theme }) => theme.neutral.first};
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        color: ${({ theme }) => theme.primary.first};

        a {
          color: ${({ theme }) => theme.primary.first};
        }
      }
    }

    .rc-pagination-prev,
    .rc-pagination-next {
      .rc-pagination-item-link {
        background-color: transparent;
        border: none;
        border-radius: 8px;
        outline: none;

        &::after {
          width: 28px;
          height: 28px;
          color: ${({ theme }) => theme.primary.first};
          font-size: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .rc-pagination-item-active {
      background: ${({ theme }) => theme.primary.third};

      a {
        color: ${({ theme }) => theme.primary.first};
      }
    }

    .rc-pagination-jump-next {
      .rc-pagination-item-link {
        background-color: transparent;
        border: none;
        border-radius: 8px;
        outline: none;

        &::after {
          width: 28px;
          height: 28px;
          padding-top: 4px;
          color: ${({ theme }) => theme.neutral.first};
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
      }
    }
  }
`;
