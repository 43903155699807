import { createSlice } from "@reduxjs/toolkit";

import {
  createWithdrawalRequisitionReducer,
  fetchWithdrawalPairUnitsReducer,
  getProfitsInfoReducer,
} from "./userBalanceReducer";

const initialState = {
  profits: null,
  profitLoading: true,
  errors: null,
  withdrawalPairLoading: true,
  withdrawalPair: null,
  createWithdrawalLoading: false,
};

const userBalanceSlice = createSlice({
  name: "userBalance",
  initialState,
  reducers: {
    clearStateUserBalance: (state) => {
      state.profitLoading = true;
      state.profits = null;
      state.errors = null;
      state.withdrawalPair = null;
    },
  },
  extraReducers: (builder) => {
    getProfitsInfoReducer(builder);
    fetchWithdrawalPairUnitsReducer(builder);
    createWithdrawalRequisitionReducer(builder);
  },
});

export const { clearStateUserBalance } = userBalanceSlice.actions;

export default userBalanceSlice.reducer;
