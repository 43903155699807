import Select, { Option } from "rc-select";
import React from "react";
import { useTranslation } from "react-i18next";

import { StyledSelect, StyledSelectLabel } from "../../styles/styledSelect";
import { useFilterData } from "./FilterContext";

const FilterSelect = ({ label, name, options, loading = false, showSearch, skipAll = false }) => {
  const { setFilterData, filterData } = useFilterData();
  const { t } = useTranslation("translation", { keyPrefix: "utils.select" });

  const value = filterData[name];

  const handleChangeFilter = (name, value) => {
    setFilterData((prev) => ({ ...prev, page: 1, [name]: value }));
  };

  if (loading) {
    return <></>;
  }

  return (
    <StyledSelect className="input-group">
      <StyledSelectLabel as="label">{label || "Status"}</StyledSelectLabel>
      <Select
        showSearch={showSearch}
        className="custom-select"
        name={name}
        value={value ? value : null}
        defaultValue={null}
        onChange={(value) => handleChangeFilter(name, value)}
        getPopupContainer={(event) => {
          // render popup in a parent container
          return event.parentNode.parentNode;
        }}
      >
        {!skipAll && (
          <Option value={null}>
            <div className="option-select-item">{t("all")}</div>
          </Option>
        )}

        {options?.map((item, key) => (
          <Option value={item?.value || item} key={key}>
            <div className="option-select-item">{item?.label || item}</div>
          </Option>
        ))}
      </Select>
    </StyledSelect>
  );
};

export default React.memo(FilterSelect);
