import styled from "styled-components";

export const StyledScrollTable = styled.div`
  overflow: auto;
  overflow-scrolling: touch;
  outline: none !important;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 4px;
    background-color: ${({ theme }) => theme.neutral.five};
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.neutral.five};
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    height: 4px;
    background-color: ${({ theme }) => theme.primary.first};
    border-radius: 12px;
  }
`;

export const StyledTable = styled.div`
  ${({ width }) => width && `min-width: ${width}px`};
  ${({ width }) => width && "width: 100%"};
  ${({ width }) => width && "overflow-x: hidden;"};
  ${({ withScroll }) =>
    withScroll === "true" &&
    `
    width: fit-content;
  `};
  font-size: ${({ size }) => size + "px"};
  border-radius: 24px;
  background-color: ${({ theme }) => theme.mainTemplateBackgroundColor};
  border: 1px solid ${({ theme }) => theme.neutral.five};

  @media screen and (max-width: 992px) {
    border-radius: 12px;
    min-width: 100%;
    border: none;
    background-color: transparent;
  }
`;

export const StyledTableHead = styled.div`
  min-height: 60px;
  padding: 20px 16px;
  color: ${({ theme }) => theme.neutral.first};
  font-weight: 500;
  background-color: ${({ theme }) => theme.primary.third};
  border-radius: 24px 24px 0 0;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(${({ col }) => col}, 1fr);
  align-items: center;
  grid-column-gap: 15px;

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const StyledTableBody = styled.div``;

export const StyledTableRow = styled.div`
  min-height: 60px;
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  padding: 14px 16px;
  color: ${({ theme }) => theme.neutral.first};
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(${({ col }) => col}, 1fr);
  align-items: center;
  grid-column-gap: 15px;
  border-top: 1px solid ${({ theme }) => theme.neutral.five};

  .table-actions {
    justify-content: flex-end;
  }

  &:last-child {
    border-radius: 0 0 24px 24px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.primary.fourth};
  }

  @media screen and (max-width: 992px) {
    margin-bottom: 16px;
    padding: 16px;
    background-color: ${({ theme }) => theme.mainTemplateBackgroundColor};
    border-radius: 12px !important;
    border: none;
    border-bottom: 1px solid #f1f1f1;
    grid-template-rows: ${({ scroll }) =>
      scroll === "auto" ? "1fr" : `repeat(${({ col }) => col}, minmax(50px, auto))`};
    grid-template-columns: 100%;
    position: relative;

    &:hover {
      background-color: ${({ theme }) => theme.mainTemplateBackgroundColor};
    }

    & > div {
      min-height: 17px;
      display: inline-flex;
      padding: 0 0 0 50%;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    .table-actions {
      &::before {
        display: none;
      }

      padding: 0;
      justify-content: center;
    }
  }
`;

export const StyledCol = styled.div`
  display: ${({ inline }) => (inline ? "inline-flex" : "grid")};
  ${({ display }) => `display: ${display}`};
  align-items: ${({ align }) => align || "center"};
  justify-content: ${({ justifyContent }) => justifyContent || "start"};
  position: relative;

  &:before {
    content: attr(data-title);
    max-width: 50%;
    color: ${({ theme }) => theme.neutral.third};
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    top: 0;
    left: 0;
    display: none;

    @media screen and (max-width: 992px) {
      display: block;
    }
  }

  @media screen and (max-width: 992px) {
    padding-top: 20px;
    align-items: start;
  }
`;

export const StyledActions = styled.div`
  @media screen and (max-width: 992px) {
    width: 100%;
    padding: 16px 0 0 !important;
    margin: 0 !important;
    order: 2;

    button {
      margin: 0 auto;
      width: 50%;
    }
  }

  @media screen and (max-width: 576px) {
    button {
      width: 100%;
    }
  }
`;
