import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const getProfitsInfo = createAsyncThunk(
  "userBalancesSlice/getProfitsInfo",
  async (id, thunkAPI) => {
    try {
      const url = id ? `/api/balances?user=${id}` : "/api/balances";
      const response = await axiosInstance(url, userAuthenticationConfig());

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const createWithdrawalRequisition = createAsyncThunk(
  "userBalancesSlice/createWithdrawalRequisition",
  async ({ paymentSystemType, wallet, amount, callback }, thunkAPI) => {
    try {
      const url = "/api/exchanger-withdrawal-requisitions";

      const response = await axiosInstance.post(
        url,
        {
          paymentSystemType,
          wallet,
          amount,
        },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        callback,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const fetchWithdrawalPairUnits = createAsyncThunk(
  "userBalancesSlice/fetchWithdrawalPairUnits",
  async (_, thunkAPI) => {
    try {
      const url = "/api/pair-units/for-withdrawal-requisition";

      const response = await axiosInstance(url, userAuthenticationConfig());

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

export { createWithdrawalRequisition, fetchWithdrawalPairUnits, getProfitsInfo };
