import Dialog from "rc-dialog";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";

import eventBus from "../../../utils/eventBus";
import { StyledButton } from "../../styles/styledButton";

const AutoLogoutModal = ({ visible, setVisible }) => {
  const { t } = useTranslation("translation", { keyPrefix: "alerts.autoLogoutModal" });

  const handleOnIdle = () => {
    if (localStorage.getItem("token")) {
      eventBus.dispatch("logout", { expired: true });
    }
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 30 * 60 * 1000,
    debounce: 500,
    onIdle: handleOnIdle,
  });

  return (
    <Dialog
      visible={visible}
      wrapClassName="default-modal-wrapper"
      onClose={() => setVisible(false)}
      animation="zoom"
      maskAnimation="fade"
      title={t("title")}
      forceRender={false}
      className="default-modal"
    >
      <div className="default-modal__content">
        <p>{t("description1")}</p>
        <p>{t("description2")}</p>
      </div>
      <div className="default-modal__footer">
        <StyledButton color="main" border onClick={() => setVisible(false)}>
          {t("okButton")}
        </StyledButton>
      </div>
    </Dialog>
  );
};

export default AutoLogoutModal;
