const examinationPermissionEndpointsUser = () => {
  if (!localStorage.getItem("endpoints")) {
    return false;
  }

  const { endpoints } = JSON.parse(localStorage.getItem("endpoints"));

  if (endpoints && endpoints.length > 0) {
    return endpoints.every((item) => item.name.startsWith("api_exchangers") && item.hasFullAccess);
  }
};

export { examinationPermissionEndpointsUser };
