import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchOrderDetails = createAsyncThunk(
  "orderDetailsSlice/fetchOrderDetails",
  async (orderId, thunkAPI) => {
    try {
      const response = await axiosInstance(`/api/orders/${orderId}`, userAuthenticationConfig());

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const createPaymentInvoiceByOrder = createAsyncThunk(
  "orderDetailsSlice/createPaymentInvoiceByOrder",
  async (orderId, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/create-payment-invoice",
        { orderId: orderId },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const updateOrderStatus = createAsyncThunk(
  "orderDetailsSlice/updateOrderStatus",
  async ({ id, newStatus }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/update-order/${id}`,
        { status: newStatus },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export { createPaymentInvoiceByOrder, fetchOrderDetails, updateOrderStatus };
