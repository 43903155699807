import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const getReportsInfo = createAsyncThunk("reports/getReportsInfo", async (filterUrl, thunkAPI) => {
  try {
    const response = await axiosInstance(
      `/api/exchanger-orders-report${filterUrl}`,
      userAuthenticationConfig()
    );

    return {
      data: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data?.data);
  }
});

export { getReportsInfo };
