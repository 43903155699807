import styled from "styled-components";

import { StyledContentWrapper } from "../styles/styledContainer";

export const StyledRegistrationWrapper = styled(StyledContentWrapper)`
  padding-top: 50px;
  max-width: 420px;
  margin: 0 auto;

  .registration-action {
    text-align: center;
    margin-top: 9px;
  }

  ${({ loading }) =>
    loading === "true" &&
    `&:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: inherit;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.55);
    z-index: 10;
  }`}
`;

export const StyledRegistrationForm = styled.div`
  width: 100%;
  margin: 0 auto;
`;
