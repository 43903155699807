import { t } from "i18next";
import React from "react";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import getUserInfo from "../getUserInfo";

const PUBLIC_PERMISSION = { name: "public", hasFullAccess: true };

function withPermissionCheckComponent(WrappedComponent, permissions) {
  // eslint-disable-next-line react/display-name
  return function (props) {
    const userPermissions = getUserInfo()?.endpoints;

    if (!userPermissions) {
      return closableNotification(t("alerts.notFoundUserPermission"), "error");
    }

    const propsWithPermissions = {
      ...props,
      permissions: userPermissions,
    };

    if (!permissions || permissions.some((perm) => perm.name === PUBLIC_PERMISSION.name)) {
      return <WrappedComponent {...propsWithPermissions} />;
    }

    const hasPermission = permissions.some((perm) =>
      userPermissions.some((userPermission) => userPermission.name === perm.name)
    );

    if (!hasPermission) {
      return null;
    }

    return <WrappedComponent {...propsWithPermissions} />;
  };
}

export default withPermissionCheckComponent;
